import styled from 'styled-components'

const PCdisk = styled.div`
    width: 20%; 
    height: 20%;
    top: ${props => Math.floor(props.position/props.gridSize)*(70/(props.gridSize-1)) + 5}%;
    left: ${props => (props.position%props.gridSize)*(70/(props.gridSize-1)) + 5}%;
    background: left top no-repeat scroll;
    background-image:  url("images/${props => props.colour}disk.png");
    z-index: 27;
    background-size: 100% 100%;
    position: absolute;
    animation: fadeIn 1s;
    /* opacity: ${props => props.isDiskOpaque ? 1 : 0};
    transition: 200ms; */
    @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }
`

export default PCdisk

