import { Button, Col, Row } from 'react-bootstrap';
import React, { useMemo, useState, useEffect } from 'react';
import { useApp } from './contexts/AppContext'
import NumBtnStyledCombo from './NumBtnStyledCombo';


export default function HundredSquare({ cardNum, onNumberClicked  }) {
        // console.log('HundredSquare render')
        const [countState, setCountState] = useState(0)
        const [change, setChange] = useState(0)
        const {    appSize,
                numberRange, numberViewRange, setNumberViewRange,
                earnedNumbers, 
                securedNumbers,
                clickedNumbers, foundNumbers
        } = useApp()
        let {low,high} = numberViewRange
        // console.log ('securedNumbers', securedNumbers)
        // console.log ('foundNumbers', foundNumbers)

//    function onViewRangeChange(diff) {
    useEffect (() => {
        const newViewRange = numberViewRange
        if (newViewRange.low + change >= numberRange.low && newViewRange.high + change <= numberRange.high) {
            newViewRange.low = newViewRange.low + change
            newViewRange.high = newViewRange.high + change
            setNumberViewRange(newViewRange)
            setChange(0)
            // console.log('numberViewRange', numberViewRange) 
            
        }        
    }, [change, numberViewRange ])

    
    // }
        
    // console.log('numberViewRange', numberViewRange)    
    // console.log('change', change)    
    const numberRangeConst = useMemo(() => range(low,high), [low, high])
    return (
        (numberRange !== undefined && low !== undefined) ?  <>
            <Row><Col>
            {(numberRange.low < low) ? <Button className='mb-2 mt-3' onClick={() => {
                const tempChange = -10
                // onViewRangeChange(tempChange) 
                // if (numberRange.low < low + tempChange){
                    setChange(tempChange)                    
                // }

                }}>lower numbers {low - 10}</Button> : ''}</Col>
                <Col></Col>
            {/* { <Button className='m-2' onClick={() => {
                setChange(0)
                console.log('buttonPressed')
                }} >Current Range: {low+change} - {high+change}</Button>} */}
                <Col>
            {(numberRange.high > high) ? <Button className='mb-2 mt-3' onClick={() => {
                const tempChange = 10
                // onViewRangeChange(tempChange) 
                // if (numberRange.high > high + tempChange){
                    setChange(tempChange)                    
                // }
            }
            }>higher numbers {high+10}</Button> : ''}
            </Col><Col></Col> </Row>
        <div 
            className='tour-numBtns'
            style={{
                
                display: 'grid',
                position: 'absolute',
                alignItems: 'center',
                gridTemplateColumns: `repeat(10, ${appSize/14}px)`,
                gap: `${appSize/40}px`
        
        }}>
            {/* Not optimised will run each time it renders */}
            
            

            { numberRangeConst.map(value => {
                return <NumBtnStyledCombo
                    btnOpacity = {earnedNumbers[value]}
                    appSize = {appSize} 
                    value={value} key={value} 
                    onClick={() => onNumberClicked(value) } 
                    isFlipped={clickedNumbers.includes(value) && cardNum !== value} 
                    isFoundNumber={foundNumbers.includes(value)}
                    isCorrectAnswer={cardNum===value}
                    isSecured={securedNumbers.includes(value)}
                />
//quest! how could I turn the 100 square upside-down so that it runs 1 - 10 left to right on the bottom row and 11 - 20 on the row above it etc.
            })}                
                
                

        
        </div>


                       </> : null
 
    )
}

HundredSquare.whyDidYouRender = true

const range = (min, max) => {
    const output = [];
    for (let index = min; index <= max; index++) {
      output.push(index);
      
    }
    return output;
  }
  
  