import React, {useEffect} from 'react'
import { useAuth } from './contexts/AuthContext'
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom"

export default function Logo() {

  return (
    <Container style={{        
        position: "absolute",
        top: "1%", 
        left: "1%"
        }}><Row>
            <Col md={2}><a href='https://mathsbycolour.com'><img src="images/logo.gif" alt="logo" height="80px" /></a></Col>
        <Col md={1}></Col><Col className="mt-2 mb-4" md={6}><UserStatus /></Col>
      </Row>
      </Container>
  )
}

const UserStatus = () => {
    const { currentUser, handleLogout } = useAuth()

    useEffect(() => {
      // console.log(currentUser)
    }, [currentUser])
    // console.log(currentUser)
    return (
       <><p style={{fontSize: 'large', color: 'white'}}>Hi  {currentUser.userName}</p>{currentUser.uid ? <><p style={{
        fontSize: 'large'}}> You are logged in as {currentUser.email} <Button variant="link" onClick={handleLogout}>Log Out</Button>
        {/* ...or <Link to="./update-profile">Update Profile</Link> */}
        </p></> : <><p style={{
            fontSize: 'large'}}> <Link to="/login">Log in</Link> or <Link to="/signup">Create an account</Link> to save your results.</p></>}</> 
    )
}
