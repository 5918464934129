import React, { useReducer } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useApp } from './contexts/AppContext'

const TOUR_STEPS = [
 
  {
    target: ".tour-colourBtns",
    content: "Click on one of the coloured buttons at the top (black and white aren't so useful).",
    disableBeacon: true
  },
  {
    target: ".tour-disks",
    content: "Click on disks to get rid of them (Wait till after the tips finish though)."
  },
  {
    target: ".tour-numBtns",
    content: "To guess what the current number on the card is, press one of these buttons."
  }
];


// Tour component
export default function Tour() {
  const { 
    helpModeOn, setHelpModeOn,
  } = useApp() 
// Initial state for the tour component
const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: helpModeOn,
  continuous: true, // Show next button
  loading: false,
  stepIndex: 0, // Make the component controlled
  steps: TOUR_STEPS
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // start the tour
    case "START":
      console.log('reset');
      return { ...state, run: true };
    // Reset to 0th step
    case "RESET":
      console.log('reset');
      return { ...state, stepIndex: 0 };
    // Stop the tour
    case "STOP":
      console.log('reset');
      setHelpModeOn(false);
      return { ...state, run: false };
    // Update the steps for next / back button click
    case "NEXT_OR_PREV":
      console.log('reset');
      return { ...state, ...action.payload };
    // Restart the tour - reset go to 1st step, restart create new tour
    case "RESTART":
      console.log('reset');
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date()
      };
    default:
      return state;
  }
};

// Listen to callback and dispatch state changes
const callback = data => {
  const { action, index, type, status } = data;

  if (
    // If close button clicked then close the tour
    action === ACTIONS.CLOSE ||
    // If skipped or end tour, then close the tour
    (status === STATUS.SKIPPED && tourState.run) ||
    status === STATUS.FINISHED
  ) {
    dispatch({ type: "STOP" });
  } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
    // Check whether next or back button click and update the step
    dispatch({
      type: "NEXT_OR_PREV",
      payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
    });
  }
};
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

    return (
      <>
        <JoyRide 
          {...tourState}
          callback={ callback }
          showSkipButton={true}
          locale={{
            last: "End Tour", 
            skip: "Close Tour"
          }}
         />
      </>
    );
  };

  
 