import React, { useState, useEffect } from 'react'
import { useApp } from './contexts/AppContext'
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from './contexts/AuthContext'
import CountdownTimer from './CountdownTimer';

export default function Countdown() {
        const { timeUntilNextSession, timeOfLastNewFind, 
          dateTimeFromUnixMillisecs, timeDiffInHoursMinsSecs, 
          formatSingleDigitMinsSecs, convertMillisecsToHMS } = useApp() 
        const { currentUser, handleLogout } = useAuth()
        const [timeLeft, setTimeLeft] = useState('')
        const navigate = useNavigate()
        const numFont = {
            fontSize: "40px",
            fontWeight: "700",
            textAlign: "center"
        }
        const titleFont = {
            fontSize: "18px",
            fontWeight: "900"
        }
        const nextSeshOpen = timeOfLastNewFind + 8*60*60*1000;
        console.log('timeOfLastNewFind', timeOfLastNewFind);

        

        // function outputTimeLeft(timeDiff){
        //     let countdown = timeUntilNextSession(lastFind);
        //     console.log('countdown', countdown)
        //     const hours = countdown.hours;
        //     const minutes = formatSingleDigitMinsSecs(countdown.minutes);
        //     const seconds = formatSingleDigitMinsSecs(countdown.seconds);
        //     console.log('time returned', `${hours}:${minutes}:${seconds}`)
        //     return `${hours}:${minutes}:${seconds}`;
        // }
        // useEffect(() => {
        //   const timeNow = Date.now();
        //   const nextSeshOpen = timeOfLastNewFind + 8*60*60*1000;
        //   const timeDiff = timeDiffInHoursMinsSecs(timeNow, nextSeshOpen);
        //   setTimeLeft(timeDiff);

        //   }, [timeOfLastNewFind]);

        
    
      return (
    <div className="App">
          <div style={{
            position: "absolute",
            top: "1%", 
            left: "1%"
            }}><a href='https://mathsbycolour.com'><img src="images/logo.gif" alt="logo" height="80px" /></a><br />
            {currentUser ? <Button variant="link" onClick={handleLogout}>Log Out</Button> : <Link to="/login">Log in</Link>}<br></br>
          </div>
            <Container fluid="xxl"> 
            <Card style={{
                padding: "2%",
                top: "20%",
                left: "20%",
                width: "60%",
                position: "absolute", 
                borderRadius: "4%",
                backgroundImage: "url('images/multiplicative.png')",
                backgroundSize: "100%",
                backgroundColor: "transparent",
                zIndex: "40"
              }}>
    
                  <Card.Body>
                <h2 className="mb-4">{'Your Next Session'}</h2>
              <Row 
                className="mb-5">
                <Col md={2}>
    
                </Col>

                <Col md={8}>
                  <CountdownTimer targetDate={ nextSeshOpen } />
                    {/* <p style={titleFont}>Time Until Your Next Session</p>
                    <span style={numFont}>{timeLeft}</span>   */}
                </Col>
                <Col md={2}>
    
                </Col>
              </Row>          
              </Card.Body>
              </Card> 
            </Container>
    </div>
    
    )
}
    

