import React, { useState, useEffect } from 'react'
import Logo from './Logo'
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import SurveyQuestion from './SurveyQuestion'
import { useNavigate } from "react-router-dom"
import { useApp } from './contexts/AppContext'
import { useAuth } from './contexts/AuthContext'


export default function ExitSurvey() {
const [index, setIndex] = useState(0)
const { 
  surveyNumbers, setDataInLogger, updateDataLogger, dataLogger, userID, scoreDocId, sessionDocId, dashDocId, areDataIdsReady, dateObject,
} = useApp()
const { currentUser } = useAuth() 

const navigate = useNavigate()

useEffect(() => {
  if (!areDataIdsReady){
    navigate("/")
  }

  setQuestions(jsonQuestions)
}, [])

const jsonQuestions = [{ questionText:'How did you feel about doing this session?',
isCard: false,
questionImage: 'feelings',
optionType:'image',
options:['inspired', 'good', 'frustrated', 'confused', 'sad']
}]

  for (let index = 0; index < surveyNumbers.length; index++) {
    // console.log('surveyNumbers', surveyNumbers)
    jsonQuestions.push( setQuestions(index) )
      }
function setQuestions(index){

      return { questionText:'How well can your mind recall the number behind this card?',
      isCard: true,
      cardNum: surveyNumbers[index],
      optionType:'image',
      options:['immediate', 'quickCalc', 'longCalc', 'nothing']
      }
 
 

}

function implodeComments(){
  let feedbackSession = [];
  // console.log("dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs]", dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs])
  if (dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs]){
    for (let index = 0; index < Object.values(dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs]).length; index++) {
      // console.log("dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs][index]['comment']",dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs][index]['comment'])
      // console.log("dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs][index]['comment']",dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs][index]['comment'])
      if (dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs][index]['comment'] && Object.values(dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs][index]['comment']).length > 0){
        const commentString = Object.values(dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs][index]['comment']).join('');
        // console.log('commentString', commentString)
        const tempQuestion = {...dataLogger['feedbackLog']['session@(ms)' + dateObject.millisecs][index]}
        tempQuestion['comment'] = commentString
        feedbackSession.push(tempQuestion);
      }
      
    }      
    // console.log('feedbackSession', feedbackSession)      
    
    setDataInLogger({['session@(ms)' + dateObject.millisecs]: feedbackSession}, 'feedbackLog');

    updateDataLogger(feedbackSession, ['feedbackLog','session@(ms)' + dateObject.millisecs], dataLogger);



  }
}





function nextQuestion() {
  // console.log('jsonQuestions', jsonQuestions, index)
  if(index < jsonQuestions.length - 1){
    setIndex(oldIndex => oldIndex + 1)
    return
  }
  if(currentUser.userName !== 'guest'){
    implodeComments()
  }

  navigate("/outro")
}



  return (
    <div className="App">
      <Logo />

      <Container fluid="xxl"> 
        <Card style={{
            // visibility: hasStarted ? "hidden" : "visible",
            padding: "2%",
            color: "white",
            top: "20%",
            left: "20%",
            width: "60%",
            position: "absolute", 
            borderRadius: "4%",
            backgroundImage: "url('images/additive.png')",
            backgroundSize: "100%",
            backgroundColor: "transparent",
            zIndex: "40"
          }}>
            {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? (
              <>
                <p>It seems like you're using a mobile device.  Unfortunately this app doesn't work on mobile devices yet!</p>
                <p>Please try on a desktop or laptop.  Sorry for the inconvenience.</p>
              </>
            ) : (
              <><Card.Body>
                <SurveyQuestion
                    jsonQuestion={jsonQuestions[index]}
                    questionNum={index}

                />

          </Card.Body>
          <Card.Footer>
                {/* Possibly for navigation */}
                <Col></Col>
                <Col>
                <Button 
                  onClick={nextQuestion}
                   >Next</Button>
                </Col>

           
          </Card.Footer></>)}


          </Card> 
        </Container>
      </div> 
  )
}
