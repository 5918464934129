import styled from 'styled-components'

// console.log('NumBtnBack render')


const NumBtnBack = styled.div`
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: ${props => props.appSize/200}px;
    transform: rotateY(180deg);
    backface-visibility: hidden;
`

export default NumBtnBack