import React, { useEffect, useState } from 'react'
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { useApp } from './contexts/AppContext'
import { Link, useNavigate } from "react-router-dom"
import { db } from "./firebase"
import { doc, setDoc } from "firebase/firestore"
import CountdownTimer from './CountdownTimer';
import PCcard from "./PCcard";



export default function Outro() {
    const { 
        pcArray,
        isCardFlipped, setCardFlipped,
        disks2Display, setDisks2Display, getFreePositionsByNum,
        GRID_SIZE, appSize,
        userID, cardNum,
        earnedNumbers, testNumbers,
        timeOfLastNewFind,
        memoryEstimate, awards,
        setHelpModeOn, setHasStarted, goToOutro, setGoToOutro,
        dataLogger,feedbackDocId, setDataInLogger, dateObject, updateDataLogger
      } = useApp() 
      const { currentUser, handleLogout } = useAuth()
    const navigate = useNavigate()
    const [isColourBtnActive, setIsColourBtnActive] = useState(false);
    const titleFont = {
      fontSize: "18px",
      fontWeight: "900"
  }
  const normalFont = {
    fontSize: "15px",
    fontWeight: "600"
}

    const signedInMessage = ["Try to remember this number but don't write it down.  You want to train your mind to store it in long term storage.  Take a break from this for at least 7 hours and then see how many of the numbers you've found so far, your mind can recall.", "Each number you find, you should try to recall the next time you get to play on the app.  Try to forget the numbers by not thinking about them until you play again and see if you can recall them then." ]
    const guestMessage = "The cards you find and recall are not saved if you aren't signed in but you can explore all you like.  Click below to carry on exploring. Sign in to save your results."
    let randomMessage = 0;
//     const [prevCardsFound, setPrevCardsFound] = useState(earnedNumbers.filter(num => !isNaN(num)).length)

// console.log('earnedNumbers', earnedNumbers.filter(num => !isNaN(num)).length, earnedNumbers)
// console.log( 'prevCardsFound', prevCardsFound)
useEffect(() => {
    // console.log('timeOfLastNewFind', timeOfLastNewFind);
    randomMessage = Math.floor(Math.random()*signedInMessage.length);
    if(!goToOutro){
      // console.log({goToOutro})
        navigate('/');
    }
    else {

      displayCardNum(cardNum)
      setGoToOutro(false);
    }    
}, [])

// useEffect(() => {
//   if(currentUser.userName !== 'guest'){

//   setDataInLogger(feedbackDocId, 'feedbackLog');
//   }
// }, [dataLogger])

function amtOfDisks(pcNumArray){
  // console.log({currentUser},{userID})
    let returnVal = 0
    // console.log('pcNumArray', pcNumArray)
    for (let index = 2; index < pcNumArray.length-1; index++) {
        // console.log(pcNumArray[index])
        returnVal = returnVal + pcNumArray[index]
    }
    return returnVal 
}

function displayCardNum(num){
    // console.log('pcArray[num]', pcArray[num])
    const colourArray = ['black', 'white', 'red', 'green', 'blue', 'purple', 'yellow', 'orange', 'pink']
    setDisks2Display([])
    const positions = getFreePositionsByNum(amtOfDisks(pcArray[num]))
    // console.log('positions', positions)
    let count = 0
    for (let index = 2; index < pcArray[num].length-1; index++) {
        const numDisks = pcArray[num][index];
        const colour = colourArray[index]

        for (let j = 0; j < numDisks; j++) {
            // console.log('colour', colour)
            setDisks2Display((oldDisks2Display) => {
                const diskID = colour + count + (new Date).getTime()
                // console.log('diskID', diskID)
                
                const newDisk = {
                  diskID,
                  colour, 
                  position: positions[count%positions.length], 
                  gridSize: GRID_SIZE                        
                }
                count++
                // console.log('newDisk', newDisk)
              return [...oldDisks2Display, newDisk]
            })
            
        }
        
    }
}

function startSoloSession() {
    setHelpModeOn(false)
    startSession()
  }

  function startHelpedSession() {
    setHelpModeOn(true)
    // setDoc(doc(db, 'users/'+ userID), {helpOn: true}, {merge: true})

    startSession()
  }

function startSession() {
  setHasStarted(true)
  //console.log('testNumbers', testNumbers.length)
  setGoToOutro(true);
  setCardFlipped(false);
  // console.log('goToOutro', goToOutro)
  if (testNumbers.length > 0){
    navigate("/flashTest")
  }
  else {
  navigate("/explore")          
  }       
}
    useEffect(() => {

        // console.log('cardFlipped', isCardFlipped)
        const interval2 = setInterval(() => {      
            setCardFlipped(!isCardFlipped)
            // console.log('cardFlipped', isCardFlipped)

     }, 2500)
     return () => clearInterval(interval2);
    }, [isCardFlipped]);




  function welcomeMessage(){
        const msg = {
          heading: '',
          challenge: '',
          info: '',
          button: ''
        }
        // msg.heading = TITLES[getvideo()]
        msg.button = (currentUser.userName !== 'guest') ? 'Get Started' : 'Click Here to Start Playing'
        // msg.video = currentUser ? '' : '<iframe width="280" height="158" src="https://www.youtube.com/embed/lG2MdfuPJ5o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
        // msg.challenge = CHALLENGES[getvideo()]
        msg.info = "The colours show the connections and patterns between numbers.  As you learn to see numbers this way, you can start to play with numbers differently."
        return msg
      }

  return (
<div className="App">
      <div style={{
        position: "absolute",
        top: "1%", 
        left: "1%"
        }}><a href='https://mathsbycolour.com'><img src="images/logo.gif" alt="logo" height="80px" /></a><br />
        {(currentUser.userName !== 'guest') ? <Button variant="link" onClick={handleLogout}>Log Out</Button> : <Link to="/login">Log in</Link>}
      
      </div>

      <Container fluid="xxl"> 
        <Card style={{
            // visibility: hasStarted ? "hidden" : "visible",
            padding: "2%",
            top: "20%",
            left: "20%",
            width: "60%",
            position: "absolute", 
            borderRadius: "4%",
            backgroundImage: "url('images/multiplicative.png')",
            backgroundSize: "100%",
            backgroundColor: "transparent",
            zIndex: "40"
          }}>
          <Card.Body>
            <h2 className="mb-4">{welcomeMessage().heading}</h2>
          <Row 
            className="mb-5">
            <Col md={1}></Col>
            <Col md={4} 
            style={{height: `${appSize*0.5}px`}}>
                <PCcard 
                    cardNum = {cardNum}
                    cardSize = {appSize*0.5}
                    onDiskClicked = {(diskID) => {
                        // console.log('onDiskClicked', diskID)

                    }}
                    onColourBtnClicked = {(multiple, colour) => {
                    // console.log('onColourBtnClicked', colour, multiple)
                    }}
                    isColourBtnActive = { isColourBtnActive }
                    
                />    </Col>           
            <Col md={1}></Col>
            <Col md={5}>
            <p style={titleFont}>You've found a new number!</p>
            <p style={normalFont}>{(currentUser.userName !== 'guest') ? `${signedInMessage[randomMessage]}` : `${guestMessage}`}</p>
              
                
            </Col>

                            
            <Col md={1}>

            </Col>
          </Row>            
          </Card.Body>
          <Card.Footer>
            { (currentUser.userName !== 'guest') ? (<CountdownTimer 
            targetDate={ timeOfLastNewFind + 1.5*60*60*1000 } 
            numbersFound = { earnedNumbers.filter(num => !isNaN(num)).length }
            startSession = { startSession }
            startHelpedSession = { startHelpedSession }
            startSoloSession = { startSoloSession }
            welcomeMessage = { welcomeMessage() }
            />) : (
            <Row 
            className="mb-5"
            style={{ color: "white" }}>
              <Col md={1}></Col>
              <Col md={4}>
              <Link style={titleFont} to="/login">Log in</Link> or <Link style={titleFont} to="/signup">Create an Account</Link>
              </Col>
              <Col md={1}></Col>

              <Col md={5}>
            <Button 
            className="w-100"
            onClick={startSession}>{welcomeMessage().button}</Button>                 
              </Col>
              <Col md={1}></Col>
              </Row>
          )}
           
          </Card.Footer>


          </Card> 
        </Container>
      </div>
  )  
}
