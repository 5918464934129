import React from 'react';
// import ColourBtn from './ColourBtn';
import { useApp } from './contexts/AppContext'
import ColourBtnStyled from './ColourBtnStyled';

export default function ColourBtns({onColourBtnClicked, isColourBtnActive, cardSize }) {
  // console.log('ColourBtns render')
  // console.log('cardSize', cardSize)

  const { levelOfPlay, colourBtns, helpModeOn } = useApp()

  return (
    <div className='colour-btns tour-colourBtns'
      style={{
        width: '100%',
        height: '10%',

        position: 'relative'
      }}
    >  

      {colourBtns.map(colourBtn => {
        // console.log('colourBtn', colourBtn, levelOfPlay)
        if (colourBtn['level'].includes(levelOfPlay)){
          // console.log('colourBtn', colourBtn, levelOfPlay)
          return <ColourBtnStyled className={`${isColourBtnActive ? 'active': '' }`}
          cardSize = {cardSize}
          colourBtn={colourBtn} 
          onClick={() => onColourBtnClicked(colourBtn.number, colourBtn.colour) }
          key={colourBtn.number} />           
        }
      })}
    </div>
  )
}
