import React, { useEffect, useState } from 'react'
import { useAuth } from './contexts/AuthContext'
import { useApp } from './contexts/AppContext'
import { Button, Alert, Container, Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate  } from "react-router-dom"
import HundredSquare from "./HundredSquare";
import PCcard from "./PCcard";
import { db } from "./firebase"
import { doc, query, where, addDoc, setDoc, collection, getDocs, serverTimestamp, Timestamp, getDoc } from "firebase/firestore"
import Logo from './Logo'


export default function FlashTest() {
    const { 
         
        numberViewRange, setNumberViewRange, 
        onViewRangeChange,      
        colourBtns, pcArray,
        isCardFlipped, setCardFlipped,
        disks2Display, setDisks2Display,
        diskPositionDependencies, setDiskPositionDependencies,
        freeDependencies,
        randomFreePosition, getFreePositionsByNum,
        POSITION_ARRAY, 
        COLOUR2MULTIPLE, 
        GRID_SIZE,
        IsAspectRatioLandscape, 
        userID,
        levelOfPlay,
        getSecureFactors,
        dailyStreak, setDailyStreak,dailyStreakUpdated, setDailyStreakUpdated, setHighestDailyStreak, highestDailyStreak, 
        securedNumbers, setSecuredNumbers,
        numTimesFound, setNumTimesFound,
        failedAttempts, setFailedAttempts,
        firstFind, setFirstFind,
        earnedNumbers, setEarnedNumbers,
        foundNumbers,  setFoundNumbers, 
        clickedNumbers,setClickedNumbers,
        appSize, 
        score, memoryEstimate, 
        hasLoaded, dateObject,
        hasStarted, setHasStarted,
        isNotified, setIsNotified,
        sessionDocId,
        dashDocId,
        testNumbers,setFactoriseExpressions, factoriseExpressions, setIsFlashTestStarted,
        setAmtOfTestCards,
        awards, setAwards, checkAndSetAwards,
        awardInfoArray, areDataIdsReady,
        forgetCurve, 
        numberStability,
        NUMBER_STABILITY_BASE,
        getDateObject,
        amtOfDisks, chooseNumber, displayCardNum,
        setNumberOfFinds,
        setNumberOfBtnClicks, dataLogger, updateDataLogger
    } = useApp() 
        const { currentUser, handleLogout } = useAuth()     
        const navigate = useNavigate()
        const [error, setError] = useState("")
        const [isColourBtnActive, setIsColourBtnActive] = useState(false)
        const [cardNum, setCardNum] = useState(20)


//         // setDisks2Display - use PCArray to set Disks2Display here.  May need to be cleared when starting up Col2Num
//         // console.log('earnedNumbers', earnedNumbers)
//         function chooseNumber() {
//             const element = Math.floor(Math.random()*testNumbers.length)
//             // console.log('testNumbers', testNumbers)
           
//             const chosenNumber = testNumbers[element]
//             testNumbers.splice(element, 1)
//             // console.log('chosenNumber', chosenNumber)
//             // console.log('testNumbers', testNumbers)

//             setTestNumbers(testNumbers)
//             return chosenNumber
//         }
// /*
//   function primeColoursfactorise(num){
//     const primeFactors = [2, 3, 5, 7, 11, 13]
//     let returnArray = [0,1]
//     let testProduct = num
//     for (var i = 0; i < primeFactors.length; i++) {
//       let count = 0
//       let product = num
//       while (Number.isInteger(product/primeFactors[i])){
//         product = product/primeFactors[i]
//         testProduct = testProduct/primeFactors[i]
//         count++      }
//        returnArray.push(count)      } 
//      if (testProduct == 1){
//         returnArray.push(0)      }
//       else {
//         returnArray.push(1)      }
//      return returnArray  }

// */
// //test data console.log('amt of disks in 60',amtOfDisks(primeColoursfactorise(60))); console.log('amt of disks in 1',amtOfDisks(primeColoursfactorise(1)));console.log('amt of disks in 160',amtOfDisks(primeColoursfactorise(160)));   
// //     const tempPCArray = [[1,0,0,0,0,0,0,0,0]]; for (let j = 0; j <= 200; j++) { tempPCArray.push(primeColoursfactorise(j)) }
//         function amtOfDisks(pcNumArray){
//             let returnVal = 0
//             // console.log('pcNumArray', pcNumArray)
//             for (let index = 2; index < pcNumArray.length-1; index++) {
//                 // console.log(pcNumArray[index])
//                 returnVal = returnVal + pcNumArray[index]
//             }
//             return returnVal 
//         }

//         function displayCardNum(num){
//             // console.log('pcArray[num]', pcArray[num])
//             const colourArray = ['black', 'white', 'red', 'green', 'blue', 'purple', 'yellow', 'orange']
//             setDisks2Display([])
//             const positions = getFreePositionsByNum(amtOfDisks(pcArray[num]))
//             // console.log('positions', positions)
//             let count = 0
//             for (let index = 2; index < pcArray[num].length-1; index++) {
//                 const numDisks = pcArray[num][index];
//                 const colour = colourArray[index]

//                 for (let j = 0; j < numDisks; j++) {
//                     // console.log('colour', colour)
//                     setDisks2Display((oldDisks2Display) => {
//                         const diskID = colour + count + (new Date).getTime()
//                         // console.log('diskID', diskID)
                        
//                         const newDisk = {
//                           diskID,
//                           colour, 
//                           position: positions[count%positions.length], 
//                           gridSize: GRID_SIZE                        
//                         }
//                         count++
//                         // console.log('newDisk', newDisk)
//                       return [...oldDisks2Display, newDisk]
//                     })
                    
//                 }
                
//             }
//         }


       function setUpFlashTest() {
            // console.log('setUpFlashTest', testNumbers.length+' cards')
            const newCardNum = chooseNumber()

            setCardNum(newCardNum)
            displayCardNum(newCardNum)
        }

    useEffect(() => {
        // console.log({testNumbers}, testNumbers.length)
    }, [testNumbers])

    useEffect(() => {
        // console.log({factoriseExpressions})
    }, [factoriseExpressions])

    useEffect(() => {

        if(areDataIdsReady && testNumbers.length > 0){
            setIsFlashTestStarted(true)
            setUpFlashTest()                
        }
        else {
            navigate("/")
        }
            
    }, [hasStarted])

  return (

    <div className="App">

        <Container fluid> 
          <Row>
            <Col><Logo /></Col>
          {/* <Col
              style={{ 
                padding: "auto", 
                height: '70px'}}
                sm={1}
              ><a href='https://mathsbycolour.com'><img src="images/logo.gif" alt="logo" height="100%" /></a></Col>
                 <Col 
               style={{ 
                padding: "auto", 
                height: '5%'}}
              sm={IsAspectRatioLandscape ? 5 : 10}
              ></Col> */}
              <Col 
                style={{
                    padding: 'auto',
                    color: 'white',
                    fontSize: `${appSize/14}px`,
                    alignSelf: 'center'
                }}
            sm={IsAspectRatioLandscape ? 4 : 10}
              >Flash Card Test</Col>
                         

          </Row>

          <Row className="mt-5">
            <Col
                style={{
                    color: 'white',
                    fontSize: `${appSize/27}px`
                }}
            >
            <p>Score</p>
            <p>{score}</p>
            </Col>
            <Col 
               style={{ 
                padding: "auto", 
                height: appSize}}
              sm={IsAspectRatioLandscape ? 5 : 10}
              >            
              <HundredSquare  
                cardNum={cardNum}
                appSize = {appSize}
                onNumberClicked = {(value) => {
                    // console.log('securedNumbers', securedNumbers, value)
                    setClickedNumbers(oldClickedNumbers => [...oldClickedNumbers, value])
                    setNumberOfBtnClicks(oldNumberOfButtonClicks => oldNumberOfButtonClicks + 1)
                    const timestamp = Timestamp.fromDate(new Date(Date.now()))
                    const dataLabel = 'FlashNumberClicked@(s)'+timestamp.seconds
                    const dataLog = {}
                    dataLog[dataLabel] =  {
                      cardNum, 
                      numberClicked: value
                    }        


                    // console.log('foundNumbers', foundNumbers.length)
                    updateDataLogger(dataLog, ['sessionLog'], dataLogger)

                    // setDoc(doc(db, sessionDocId), dataLog, {merge: true}) 

                    const tempFailedAttempts = failedAttempts
                    tempFailedAttempts[cardNum] =  tempFailedAttempts[cardNum] + 1
                    if (value===cardNum){
                        let stability = (1+forgetCurve[value]*(1-forgetCurve[value]))*numberStability[value]
                        // How should the secure flag interact here? if secure, should it be possible to find
                        tempFailedAttempts[cardNum] =   tempFailedAttempts[cardNum] - 1
                        const tempNumTimesFound = numTimesFound
                        const secureFactors = getSecureFactors(cardNum)
                        for (let index = 0; index < secureFactors.length; index++) {
                            const element = secureFactors[index];
                            // console.log('firstFind', firstFind);
                            if(firstFind[element]) {
                                const tempFirstFind = firstFind;
                                tempFirstFind[element] = false;
                                setFirstFind(tempFirstFind);                                
                                tempNumTimesFound[element] = (!isNaN(tempNumTimesFound[element])) ? tempNumTimesFound[element] + 1 : 1;
                                setNumTimesFound(tempNumTimesFound)
                                stability = (tempFailedAttempts[element] === 0 && tempNumTimesFound[element] > 3) ? stability : stability*2
                            }
                            
                        }
                        const newEarnedNumbers = earnedNumbers

                        // console.log('stability', stability)
                        newEarnedNumbers[value] = 0
                        setNumberOfFinds(oldNumberOfFinds => oldNumberOfFinds + 1)

                        setEarnedNumbers(newEarnedNumbers)
                        setFoundNumbers(oldFoundNumbers => [...oldFoundNumbers, value])
                        setCardFlipped(true)

                        setTimeout(function(){
                            setCardFlipped(false)     
                            setClickedNumbers(oldClickedNumbers => [])                        
                            if(testNumbers.length > 0){
                                setTimeout(function(){
                                    setDiskPositionDependencies(POSITION_ARRAY)
                                    setUpFlashTest() 
                                }, 100)
                
                            }
                            else {
                                setCardNum(1)
                                displayCardNum(1)
                                const diceRoll = Math.random()*5
                                // console.log({diceRoll})
                                navigate((diceRoll > 2.3) ? (factoriseExpressions.length > 0) ? "/calculate" : "/100orBust" : "/100orBust")  
                            }
                        }, 1600)
                                            // securedNumbers, setSecuredNumbers,
                    // numTimesFound, setNumTimesFound,
                    // failedAttempts, setFailedAttempts,
                    // firstFind, setFirstFind,
                        const key = value
                        let data = {lastFlashTest: dateObject.millisecs}
                        // if( userID !== 'guest'){
                        //     updateDataLogger(data, ['userLog'], dataLogger)

                        //     // setDoc(doc(db, 'users/'+userID), data, {merge: true})
                        // }
                        // updateDataLogger(data, ['sessionLog'], dataLogger)
                        data[key] = {
                            lastTimeFound: Timestamp.fromDate(new Date()),
                            numTimesFound: (tempNumTimesFound[cardNum]) ? tempNumTimesFound[cardNum] : 1,
                            stability: (stability > NUMBER_STABILITY_BASE) ? stability : NUMBER_STABILITY_BASE
                        }
                        if( userID !== 'guest'){
                            checkAndSetAwards('award_flash_test');
                            // console.log('doctrack', data)
                            updateDataLogger(data[key], ['userLog', key], dataLogger)

                            // setDoc(doc(db, 'users/'+userID), data, {merge: true})
                        }
                        updateDataLogger(data[key], ['sessionLog', key], dataLogger)
                        
                        // setDoc(doc(db, sessionDocId), data, {merge: true})
                    }
                    //quest! this relies on the experience that async misses the update.  Is this a problem?
                    else if (clickedNumbers.length > 1){
                        if(factoriseExpressions.length > 0){
                            // setFoundNumbers(oldFoundNumbers => (oldFoundNumbers.includes(value) ? oldFoundNumbers : [...oldFoundNumbers, value]))
                            // console.log({factoriseExpressions, cardNum})
                            setFactoriseExpressions(oldFactExp => (oldFactExp.includes(cardNum) ? oldFactExp : [...oldFactExp, cardNum]))
                        }
                        // console.log('clickedNumbers.length',clickedNumbers.length, 'cardNum', cardNum)      
                        // console.log('run out of tries', clickedNumbers.length)
                        setClickedNumbers(oldClickedNumbers => []) 
                        setDiskPositionDependencies(POSITION_ARRAY)
                        if(testNumbers.length > 0){
                            setUpFlashTest()
                        }
                        else {
                            setCardNum(1)
                            displayCardNum(1)
                            const diceRoll = Math.random()*5
                            // console.log({diceRoll})
                            navigate((diceRoll > 2.3) ? (factoriseExpressions.length > 0) ? "/calculate" : "/100orBust" : "/100orBust")                       
                        }
                    }
                    setFailedAttempts(tempFailedAttempts)

                }
            }
                  
            />
            </Col>
            <Col></Col>
            <Col sm={IsAspectRatioLandscape ? 4 : 10}>
            <PCcard 
                cardNum = {cardNum}
                cardSize = {appSize}
                onDiskClicked = {(diskID) => {
                    // console.log('onDiskClicked', diskID)

                }}
                onColourBtnClicked = {(multiple, colour) => {
                // console.log('onColourBtnClicked', colour, multiple)
                }}
                isColourBtnActive = { isColourBtnActive }
                expression={null}
                
            />
            </Col>
            <Col></Col>
        </Row>
        <Row className='mt-3'>
            <Col></Col>            
            <Col></Col>
            <Col></Col>
            <Col
                style={{
                    position: 'relative'
                }}
            ><img 
            src={'images/numCards.png'} 
            style={{
                position: 'absolute',
                paddingTop: '10%'

            }}
            width='80%'
            
            /><span
                      style={{
                        padding: '15%',
                        fontSize: `${appSize/12.2}px`,
                        textAlign: 'center',
                        color: 'black',
                        position: 'absolute',
                        left: '45%',
                        top: '-20%'
                      }}
            >{testNumbers.length + 1}</span></Col>
            <Col
                style={{
                    position: 'relative'
                }}
            ><img 
                src={'images/numTries.png'}             
                style={{
                position: 'absolute',
                paddingTop: '10%'
    
                }}
                width='80%'
                /><span
                style={{
                  padding: '15%',
                  fontSize: `${appSize/12.2}px`,
                  textAlign: 'center',
                  color: 'black',
                  position: 'absolute',
                  left: '5%',
                  top: '-20%'
                }}
      >{3 - clickedNumbers.length}</span></Col>
            <Col></Col>


        </Row>
    </Container>  
    </div> //for app
  )
}
