import React from 'react';
import PCdisk from './PCDisk';
import ColourBtns from './ColourBtns';
import ExpressionDisplay from './ExpressionDisplay';
import { useApp } from './contexts/AppContext'


export default function PCcard({ onColourBtnClicked, cardNum, onDiskClicked, isColourBtnActive, cardSize, expression}) {
// const [combineMode, setCombineMode] = useState('mult')
  const {  isCardFlipped, disks2Display, appSize } = useApp()

  // console.log('disks2Display', disks2Display)
  // console.log('cardNum', cardNum)
  return (
    <div 
      // className={`PCcard ${isCardFlipped ? 'cardFlip' : ''}`}
      style={{
        position: 'absolute',
        borderRadius: '4%',
        // boxShadow:  '0 0 5px 2px rgba(255, 255, 255, .3)',
        backgroundColor: 'transparent',
        height: `${cardSize*0.9}px`,
        width: `${cardSize*0.9}px`,
        transformStyle: 'preserve-3d',
        transition: '200ms',
        transform:  `${isCardFlipped ? 'rotateY(180deg)': 'rotateY(0deg)'}  perspective(1000px)`
      }}
      >
        <div 
          className='colours'
          style={{
            width: '100%',
            height: '100%',
            background: 'left top no-repeat scroll',
            backgroundImage:  'url("/images/multiplicative.png")',
            backgroundSize: '100% 100%',
            position: 'absolute',
            backfaceVisibility: 'hidden'
          }}
        >
          {isCardFlipped ? null : (
            <><ColourBtns 
            className = 'tour-colourBtns'
            cardSize = {cardSize}
            onColourBtnClicked = {onColourBtnClicked}
            isColourBtnActive = {isColourBtnActive}  
          />
          {(expression) ? <ExpressionDisplay position={expression.position} gridSize={expression.gridSize} amtChars={expression.amtChars} appSize={appSize}>{expression.text}</ExpressionDisplay> : <></>}
          { disks2Display.map(diskInd2d => <PCdisk className='tour-disks' onClick={() => onDiskClicked(diskInd2d.diskID)} key={diskInd2d.diskID} colour={diskInd2d.colour} position={diskInd2d.position} gridSize={diskInd2d.gridSize} />

          )}</>
                )}
          
        </div>
        <div className='numbers'
          style={{
            padding: '15%',
            fontSize: `${(cardNum >= 100) ? cardSize/2.8 : cardSize/2.2}px`,
            textAlign: 'center',
            width: '100%',
            height: '100%',
            background: 'left top no-repeat scroll',
            backgroundImage:  'url("/images/number-bg.png")',
            backgroundSize: '100% 100%',
            transform: 'rotateY(180deg)',          
            position: 'absolute',
            backfaceVisibility: 'hidden'            
          }}
        >{ cardNum }</div>
    </div>  )
}
