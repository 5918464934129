
import { useApp } from '../contexts/AppContext'


const useMessaging = (prevCardsFound, memoryEstimate, awards, userID) => {

  const { timeOfLastFlashTest } = useApp()

  // console.log('timeOfLastFlashTest', timeOfLastFlashTest)

    function getChallenge(){
        // get userDoc info on videos, get memory estimate and awards data
        const nextMandatoryFlashTest = timeOfLastFlashTest + 24*60*60*1000;
        const currentTime = new Date().getTime()
        
        //console.log('cardsFound', cardsFound)
        if(userID === 'guest'){
          return Math.floor(Math.random()*2)  //returns 0 or 1
        }
        if(memoryEstimate === 0  && !awards[0]){
          // No acivity yet - video about interface
          return 2
        }
        if(memoryEstimate === 0  && awards[0]){
          // interface ok - no numbers found - video about finding numbers
          return 3
        }
        if(prevCardsFound > 0  && prevCardsFound < 5){
          //found some cards - explain scoring and memory stuff
          return 4
        }    
        if(prevCardsFound > 6 && prevCardsFound < 8 && !awards[2]){
          //explain flashCard game
          return 5
        }
        if(prevCardsFound >= 8 && (nextMandatoryFlashTest - currentTime)>0){
          //explain Daily Practice
          return 6
        }
        if(prevCardsFound >= 8 && (nextMandatoryFlashTest - currentTime) <= 0){
          //explain Daily Practice
          return 7
        }

    //default video
        return 8
      }

      function getMissionOptions(){
        const nextMandatoryFlashTest = timeOfLastFlashTest + 24*60*60*1000;
        const currentTime = new Date().getTime()
        // console.log('flashTestTest',prevCardsFound, (nextMandatoryFlashTest - currentTime))
        const mishOpts = {
            red: { label: '', action: 'login'},
            blue: {label: '', action: 'explore'}
        }
        // console.log({userID}, {currentUser})
        if(userID === 'guest'){
            mishOpts.red.label = 'Log in or Create an Account'
            mishOpts.blue.label = 'Start Exploring'
          return mishOpts;
        }
        if(memoryEstimate === 0  && !awards[0]){
          // No acivity yet - video about interface
          mishOpts.red.label = 'Find out for yourself'
          mishOpts.blue.label = 'Get Some Help'
          mishOpts.red.action = 'explore'
          mishOpts.blue.action = 'guidedTour'
        return mishOpts;
      }
        if(memoryEstimate === 0  && awards[0]){
          // interface ok - no numbers found - video about finding numbers
          mishOpts.red.label = 'Continue Finding Numbers'
          mishOpts.blue.label = 'Go on a Tour'
          mishOpts.red.action = 'Explore'
          mishOpts.blue.action = 'guidedTour'
          return mishOpts;

        }
        if(prevCardsFound > 0  && prevCardsFound < 5){
          //found some cards - explain scoring and memory stuff
          // console.log('pre Flash')
          mishOpts.red.label = 'Continue Finding Numbers'
          mishOpts.blue.label = 'See your Scores'
          mishOpts.red.action = 'explore'
          mishOpts.blue.action = 'scores'
          return mishOpts;

        }    
        if(prevCardsFound > 6 && prevCardsFound < 8 && !awards[2]){
          //explain flashCard game
          // console.log('first Flash')
          mishOpts.red.label = 'Test your Memory'
          mishOpts.blue.label = 'See your Scores'
          mishOpts.red.action = 'flash'
          mishOpts.blue.action = 'scores'
          return mishOpts;

        }
        if(prevCardsFound >= 8 && (nextMandatoryFlashTest - currentTime)>0){
          //explain Daily Practice
          // console.log('optional Flash')
          mishOpts.red.label = 'Continue Finding Numbers'
          mishOpts.blue.label = 'Test your Memory'
          mishOpts.red.action = 'explore'
          mishOpts.blue.action = 'flash'
          return mishOpts;

        }
        
        if(prevCardsFound >= 8 && (nextMandatoryFlashTest - currentTime) <= 0){
          //explain Daily Practice
          // console.log('mandatory Flash')
          mishOpts.red.label = 'Test your Memory'
          mishOpts.blue.label = 'See your Score'
          mishOpts.red.action = 'flash'
          mishOpts.blue.action = 'scores'
          return mishOpts;

        }
        
    //default video
    mishOpts.red.label = 'Log in or Create an Account'
    mishOpts.blue.label = 'explore'
    mishOpts.red.action = 'Log in or Create an Account'
    mishOpts.blue.action = 'guidedTour'
    return mishOpts;

}


    function getvideo(prevCardsFound, memoryEstimate, awards){
        // get userDoc info on videos, get memory estimate and awards data

        //console.log('cardsFound', cardsFound)
        if(userID === 'guest'){
          return [0,1]
        }
        if(memoryEstimate === 0  && !awards[0]){
          // No acivity yet - video about interface
          return [2,3,4]
        }
        if(memoryEstimate === 0  && awards[0]){
          // interface ok - no numbers found - video about finding numbers
          return [2,3,4]
        }
        if(prevCardsFound > 0  && prevCardsFound < 5){
          //found some cards - explain scoring and memory stuff
          return [3,4,6]
        }    
        if(prevCardsFound > 6 && !awards[2]){
          //explain flashCard game
          return [5,4,6]
        }
        if(prevCardsFound > 8){
          //explain Daily Practice
          return [5,7,6]
        }
    //default video
        return [8,7,6]
      }


    const supportVids = getvideo(prevCardsFound, memoryEstimate, awards).map((item) => {return({video: VIDEO_ID[item], title: TITLES[item]})});
    const mission = CHALLENGES[getChallenge()]; 
    // console.log('mission', mission)  
    const missionOptions = getMissionOptions();
    // console.log(missionOptions, missionOptions)
    return [ mission, missionOptions, supportVids]
}

export { useMessaging };

//https://youtu.be/lG2MdfuPJ5o, https://youtu.be/DnbB55cFqKs, https://youtu.be/DoCc75lES3s, https://youtu.be/QIxzNvPURiA, https://youtu.be/5lJp0whgVhg, https://youtu.be/JJ8vQ2gRXnY, https://youtu.be/ZiCOWujSeXE, https://youtu.be/PsT_MV4gvZk, https://youtu.be/4Aw7uFSOKnI
//  https://youtu.be/H9rG0UC7JYI - basic intro  https://youtu.be/1RPiNbWauCY - signing up  https://youtu.be/4E_9LXduE7o - finding the controls  https://youtu.be/KUQ95VMSYxg - Finding the Numbers  https://youtu.be/Fhqc9v5CaVs - Forgetting and Recalling  https://youtu.be/YFnbxt4_1AE -Flash cards
const VIDEO_ID = [
    'H9rG0UC7JYI',  // 0 - basic intro
    '1RPiNbWauCY',  // 1 - signing in
    '4E_9LXduE7o',  // 2 - finding the controls 
    'KUQ95VMSYxg',  // 3 - Finding the Numbers
    'Fhqc9v5CaVs',  // 4 - exploring numbers - understanding discovery and memory - Forgetting and Recalling
    'YFnbxt4_1AE',  // 5 - FlashCard Game
    
    'ZiCOWujSeXE',  // 6 - Daily Practice
    'PsT_MV4gvZk',  // 7 - Coloured disks
    '4Aw7uFSOKnI',  // 8 - Seeing your memory
  ]
  
  const TITLES = [
    'Explorers Enter Here',  // 0 - basic intro
    'Welcome to Prime Colours',  // 1 - signing in
    'Where are the Buttons?',  // 2 - finding the controls 
    'How do these numbers work?',  // 3 - Finding the Numbers
    'How many of the numbers that you found before can you remember?',  // 4 - exploring numbers - understanding discovery and memory
    'Test Your Memory!',  // 5 - FlashCard Game
    'Daily Practice - Little and often',  // 6 - Daily Practice
    'The Prime Colours System - Number by Colours',  // 7 - Coloured disks
    'If you could see into your mind...',  // 8 - Seeing your memory
    'Consistency is the Super Power!',  // 9 - Consistency
  ]
  
  const CHALLENGES = [
    'Can you work out how to use this app? Where are the buttons and controls?  What can you press?',  // 0 - basic intro
    'Sign up for an account to get the most out of the app and use it as it\'s designed to be used, little and often with long breaks.',  // 1 - signing in
    'Find the buttons to make the Prime Colours. Play with this regularly for just a short time for the most impact.',  // 2 - finding the controls 
    'Each combination of colours represents a number.  As you find and recall more numbers, you can use them to work out the system',  // 3 - Finding the Numbers
    'Reinforce your ability to remember by forgetting and trying to recall those numbers again after time has passed.  Forgetting is your friend',  // 4 - exploring numbers - understanding discovery and memory
    'You\'ll start with some Flash cards to test the numbers you\'ve already found. You can find other old numbers once in the explore section too.',  // 5 - FlashCard Game
    'Your daily practice is to recall numbers you\'ve found before, then find one new number.  You can start by choosing a FlashTest if you wish.',  // 6 - Daily Practice
    'Remembering numbers is great but how can you work them out?  What is the system?  Once you can remember some of these cards easily, look for some patterns to help you predict the numbers.',  // 7 - Coloured disks
    '...what would your memories look like? Working with your mind like a memory champion.',  // 8 - Seeing your memory
    'You don\'t need to do lots of work at once.  Just keep up a practice for a month or two and you will see the change.',  // 9 - Consistency
  ]
  