import React, {useRef, useState, useEffect} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { Container } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import Logo from './Logo'

export default function Login() {
    // console.log('Login render')

    const emailRef = useRef()
    const navigate = useNavigate()
    const passwordRef = useRef()
    const { login, error, currentUser } = useAuth() 
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        console.log('currentUser', currentUser);
        
        if(currentUser.uid){
            navigate('/');
        }

    }, [])

    async function handleSubmit(e) {
        e.preventDefault()

        setLoading(true)
        login(emailRef.current.value, passwordRef.current.value)

        setLoading(false)
    }



  return (
    <>
        <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "50vh"}}>
      <div className="w-100" style={{maxWidth: "400px"}}>
      <Logo />
        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">
                    Log in
                </h2>
                {/* {console.log("error", error)} */}
                {error && <Alert variant='danger'>{ error }</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} required></Form.Control>
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-3" type="submit">Log In</Button>
                </Form>
                <div className="w-100 text-center mt-3">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <div className="w-100 text-center mt-2">
                    Need an account?  <Link to="/signup">Sign Up</Link>
                </div>
            </Card.Body>
        </Card>

        </div>  
                
            </Container>

    </>
  )
}
