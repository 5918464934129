import React from 'react'
import { useApp } from './contexts/AppContext'
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from './contexts/AuthContext'
import Logo from './Logo'
import CanvasBar from './CanvasBar'

export default function ScoreCard() {
    const { 
        score, maxScore, memoryEstimate, runningScore, exitLink,
        dailyStreak, setDailyStreak,dailyStreakUpdated, setDailyStreakUpdated, setHighestDailyStreak, highestDailyStreak, 
        
      } = useApp() 
    const { currentUser, handleLogout } = useAuth()
    const navigate = useNavigate()
    const numFont = {
        fontSize: "40px",
        fontWeight: "700",
        textAlign: "center"
    }
    const titleFont = {
        fontSize: "18px",
        fontWeight: "900"
    }
    console.log('exitLink', exitLink);

  return (
<div className="App">
    <Logo />

      <Link to={exitLink.path} style={{
            padding: "2%",
            top: "10%",
            left: "0%",
            position: "absolute"}}><img src="images/exploreBtn.png" alt="button to explore page" width="80px" /><br></br> {exitLink.text}</Link>  
   
    <Container fluid="xxl"> 
        <Card style={{
            padding: "2%",
            top: "20%",
            left: "20%",
            width: "60%",
            position: "absolute", 
            borderRadius: "4%",
            backgroundImage: "url('images/multiplicative.png')",
            backgroundSize: "100%",
            backgroundColor: "transparent",
            zIndex: "40"
          }}>

              <Card.Body>
            <h2 className="mb-4">{'Your Stats'}</h2>
          <Row 
            className="mb-5">

            <Col md={2}><img src="images/brain.png" alt="Est. Num Cards in Memory Icon" width="80%" /></Col>
            <Col md={2}>
                <p style={titleFont}>Est. Num Cards in Memory</p>
                <p style={numFont}>{memoryEstimate}</p>
            </Col>
            <Col md={2}><img src="images/score.png" alt="Score Icon" width="80%" /></Col>
            <Col md={2}>
                <p style={titleFont}>Score</p>
                <span style={numFont}>{score}</span><span>/{maxScore}</span> 
            </Col>
            <Col md={2}><img src="images/runningTotal.png" alt="Score Icon" width="80%" /></Col>
            <Col md={2}>
            <p style={titleFont}>Total Score</p>
                <p style={numFont}>{runningScore + score}</p> 
            </Col>
          </Row>   
          <Row 
            className="mb-5">
            <Col md={1}></Col>
            <Col md={2}><img src="images/streak.png" alt="Daily Streak Icon" width="80%" /></Col>
            <Col md={3}>
                <p style={titleFont}>Daily Streak</p>
                <p style={numFont}>{dailyStreak.streak}</p>
            </Col>
            <Col md={2}><img src="images/highstreak.png" alt="Highest Daily Streak Icon" width="80%" /></Col>
            <Col md={3}>
                <p style={titleFont}>Highest Daily Streak</p>
                <p style={numFont}>{highestDailyStreak.streak}</p>  
            </Col>
            <Col md={1}>

            </Col>
          </Row>          
          </Card.Body>
          <CanvasBar
            current={score}
            total={maxScore}
          />          
          </Card> 

        </Container>
</div>
 
  )
}
