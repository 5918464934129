import { useState, useEffect } from 'react';

export default function useWindowDimensions() {

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const appWidth = hasWindow ? window.innerWidth : null;
    const appHeight = hasWindow ? window.innerHeight : null;
    
    const aspectRatio = appWidth / appHeight
    const IsAspectRatioLandscape = (aspectRatio >= 1) 
    const maxSquareSize = (aspectRatio > 2) ? appHeight : (aspectRatio < 0.5) ? appWidth : Math.max(appHeight, appWidth)/2
    const appSize = (maxSquareSize > 700) ? 700*0.92 : maxSquareSize *0.92

    return {
        IsAspectRatioLandscape,
      appSize, 
      appWidth,
      appHeight
    };
  }

  const [appDimensions, setAppDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setAppDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return appDimensions;
}

