import React, {useEffect, useState} from 'react';
import PCdisk from './PCDisk';
import ColourBtns from './ColourBtns';
import ExpressionDisplay from './ExpressionDisplay';
import { useApp } from './contexts/AppContext'


export default function StaticPCcard({ cardNum, cardSize, isHidden, left, top, isNumberClickable, isCardFlipped, onClick, onDiskClicked,  expression}) {
  const { 
         
    pcArray, getFreePositionsByNum,
    GRID_SIZE,
    appSize, 
    amtOfDisks
} = useApp() 
  
// const [combineMode, setCombineMode] = useState('mult')
// const {  isCardFlipped, disks2Display, appSize } = useApp()
    const [disksOnThisCard, setDisksOnThisCard] = useState([])
  // console.log('disks2Display', disks2Display)
  // console.log('cardNum', cardNum)

  // useEffect(() => {
    // console.log({cardNum, cardSize, cardID, left, top, isCardFlipped, onClick, onDiskClicked,  expression})
  // }, [isCardFlipped])

  useEffect(() => {
    if(pcArray.length > 0){
      // console.log({cardNum, disksOnThisCard})
      displayDisksOnThisCard(cardNum)

    }

  }, [pcArray])

  useEffect(() => {
    // console.log({disksOnThisCard})
  }, [disksOnThisCard])

  useEffect(() => {
    // console.log({cardNum, cardSize, cardID, left, top, isCardFlipped, onClick, onDiskClicked,  expression})
  }, [isCardFlipped])



  function displayDisksOnThisCard(num){
    // console.log('pcArray', pcArray)
    const colourArray = ['black', 'white', 'red', 'green', 'blue', 'purple', 'yellow', 'orange']
    setDisksOnThisCard([])
    const positions = getFreePositionsByNum(amtOfDisks(pcArray[num]))
    // console.log('positions', positions)
    let count = 0
    for (let index = 2; index < pcArray[num].length-1; index++) {
        const numDisks = pcArray[num][index];
        const colour = colourArray[index]

        for (let j = 0; j < numDisks; j++) {
            // console.log('colour', colour)
            setDisksOnThisCard((oldDisks2Display) => {
                const diskID = colour + count + (new Date).getTime()
                // console.log('diskID', diskID)
                
                const newDisk = {
                  diskID,
                  colour, 
                  position: positions[count%positions.length], 
                  gridSize: GRID_SIZE                        
                }
                count++
                // console.log('newDisk', newDisk)
              return [...oldDisks2Display, newDisk]
            })
            
        }
        
    }
}

// console.log({cardNum,isHidden})

  return (
    <div 
      // className={`PCcard ${isCardFlipped ? 'cardFlip' : ''}`}

      style={{
        visibility: `${!isHidden ? 'visible' : 'hidden'}`,
        position: 'absolute',
        left: `${left}px`,
        top: `${top}px`,
        borderRadius: '4%',
        // boxShadow:  '0 0 5px 2px rgba(255, 255, 255, .3)',
        backgroundColor: 'transparent',
        height: `${cardSize*0.9}px`,
        width: `${cardSize*0.9}px`,
        transformStyle: 'preserve-3d',
        transition: '200ms',
        transform:  `${isCardFlipped ? 'rotateY(180deg)': 'rotateY(0deg)'}  perspective(1000px)`
      }}
      >
        <div 
          className='colours'
          onClick={onClick}
          style={{
            width: '100%',
            height: '100%',
            background: 'left top no-repeat scroll',
            backgroundImage:  'url("/images/multiplicative.png")',
            backgroundSize: '100% 100%',
            position: 'absolute',
            backfaceVisibility: 'hidden',
            cursor: 'pointer'
          }}
        >
          {isCardFlipped ? null : (
            <><ColourBtns 
            className = 'tour-colourBtns'
            cardSize = {cardSize}
            onColourBtnClicked = {false}
            isColourBtnActive = {false}  
          />
          {(expression) ? <ExpressionDisplay position={expression.position} gridSize={expression.gridSize} amtChars={expression.amtChars} appSize={appSize}>{expression.text}</ExpressionDisplay> : <></>}
          { (disksOnThisCard !== undefined && disksOnThisCard.length > 0) ? <>{disksOnThisCard.map(diskInd2d => <PCdisk onClick={() => onDiskClicked(diskInd2d.diskID)} key={diskInd2d.diskID} colour={diskInd2d.colour} position={diskInd2d.position} gridSize={diskInd2d.gridSize} />)}</> : <></>

          }</>
                )}
          
        </div>
        <div className='numbers'
            onClick={onClick}
          style={{
            padding: '15%',
            fontSize: `${(cardNum >= 100) ? cardSize/2.8 : cardSize/2.2}px`,
            textAlign: 'center',
            width: '100%',
            height: '100%',
            background: 'left top no-repeat scroll',
            backgroundImage:  'url("/images/number-bg.png")',
            backgroundSize: '100% 100%',
            transform: 'rotateY(180deg)',          
            position: 'absolute',
            backfaceVisibility: 'hidden',
            cursor: `${isNumberClickable ? 'pointer' : 'not-allowed'}`           
          }}
        >{ cardNum }</div>
    </div>  )
}
