import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { useApp } from './contexts/AppContext'
import { Link, useNavigate } from "react-router-dom"
import HundredSquare from "./HundredSquare";
import Tour from "./Tour"
import ScoreTour from "./ScoreTour"
import './app.css';
import PCcard from "./PCcard";
import Awards from "./Awards";
import { db } from "./firebase"
import { doc, setDoc, Timestamp } from "firebase/firestore"
import Logo from "./Logo";


export default function Calculate() {
  // console.log('Calculate render')

  const [numberOfCardNumChanges, setNumberOfCardNumChanges] = useState(0)    
  const [isColourBtnActive, setIsColourBtnActive] = useState(true)
  const [expression, setExpression] = useState({})
  const [newDiskID, setNewDiskID] = useState('')
  const [numberOfQuestions, setNumberOfQuestions] = useState(0)
  const navigate = useNavigate()


  const { 
    numberRange, 
    score, memoryEstimate, runningScore,
    setCardFlipped,
    disks2Display, setDisks2Display, setDisks,
    cardNum, setCardNum,
    freeDependencies, randomFreePosition, 
    COLOUR2MULTIPLE, 
    IsAspectRatioLandscape, 
    appSize, GRID_SIZE,
    userID,
    dateObject,
    levelOfPlay,
    getSecureFactors,getFactors,
    dailyStreak, setDailyStreak, hourAsKeyDiff, dailyStreakUpdated, setDailyStreakUpdated, setHighestDailyStreak, highestDailyStreak, 
    numTimesFound, setNumTimesFound,
    failedAttempts, setFailedAttempts,
    firstFind, setFirstFind,
    earnedNumbers,setEarnedNumbers,
    foundNumbers, setFoundNumbers,      
    introNumbers, setIntroNumbers,      
    clickedNumbers, setClickedNumbers,
    areDataIdsReady,
    awards, checkAndSetAwards,
    helpModeOn,
    sessionDocId, dashDocId, scoreDocId, setDataInLogger,
    setIsExpressionShown,factoriseExpressions, setFactoriseExpressions, setIsFactExpStarted,
    numOfHints, setNumOfHints, showHint,
    forgetCurve, 
    numberStability,
    NUMBER_STABILITY_BASE,
    getDateObject, displayCardNum,
    setNumberOfFinds, setTimeOfLastNewFind,
    setNumberOfBtnClicks, setExitLink, dataLogger, updateDataLogger
  } = useApp() 
  const { currentUser, handleLogout, error } = useAuth() 
  let dashLog = {}
  
   useEffect(() => {

    setExitLink({path: '/calculate', text: 'Calculate'})
    setIsExpressionShown(true)

   }, [])


    useEffect(() => {
      if(currentUser !== 'guest'){
        const dataLog = {}        
        if (!areDataIdsReady){
          navigate("/")
        }
        else {
            // console.log('factoriseExpressions', factoriseExpressions)
        const timestamp = Timestamp.fromDate(new Date(Date.now()))

        const dataLabel = 'CardNum@(s)'+timestamp.seconds

        dataLog[dataLabel] = cardNum
        // console.log('docTrack',  sessionDocId)
        updateDataLogger(dataLog, ['sessionLog'], dataLogger);

        // setDoc(doc(db, sessionDocId), dataLog, {merge: true})  

        const dashKey = 'colourBtnClicks@'+userID
        dashLog[dashKey] = numberOfCardNumChanges 
        updateDataLogger(dashLog, ['dashLog'], dataLogger);

        // console.log('docTrack', )
        setDoc(doc(db, dashDocId), dashLog, {merge: true})
  
        }
        // console.log('dataLog', dataLog)
      }
    }, [cardNum])



    function testNum2Expression(testNum){
        const factors = getFactors(testNum)
        
        const factor1 = factors[Math.floor(Math.random()*factors.length)]
        if(factor1 === testNum || factor1 === 1){
            return createExpression([testNum])
        }
        
        return createExpression([factor1, testNum/factor1])
    }

    function createExpression(factors){
        const thisExpression = factors.join('x')
        const numChars = thisExpression.length
        // console.log({numChars})

        return {
            position: 5,
            gridSize: GRID_SIZE,
            amtChars: numChars,
            factors,
            text: thisExpression
        }

    }

            // setDisks2Display - use PCArray to set Disks2Display here.  May need to be cleared when starting up Col2Num
        // console.log('earnedNumbers', earnedNumbers)
        function chooseExpNumber() {
          const tempFactExp = factoriseExpressions
            const element = Math.floor(Math.random()*tempFactExp.length)
           
            const chosenNumber = tempFactExp[element]
            tempFactExp.splice(element, 1)
            // console.log('chosenNumber', chosenNumber)
            // console.log('testNumbers', testNumbers)
  // setDisks2Display((oldDisks2Display) => oldDisks2Display.filter((diskInd2d) => diskInd2d.diskID !== diskID))
            setFactoriseExpressions((oldFactoriseExpressions) => oldFactoriseExpressions.filter((factExp) => factExp !== element))
            return chosenNumber
        }
  

    function setUpCalculate(){
        // console.log('factoriseExpressions.length', factoriseExpressions.length)
        if(factoriseExpressions.length > 0 ){
            setExpression(testNum2Expression(chooseExpNumber()))
        }
        else {
            navigate('/explore')
        }
    }

    function reduceExpression(divisor, newdiskID){
        const tempExpression = expression
        for (let index = expression.factors.length -1; index >= 0 ; index--) {
            // console.log({index,divisor},expression.factors[index]/divisor)
            if(tempExpression.factors[index] === divisor){
                tempExpression.factors.splice(index, 1)
                setExpression(createExpression(tempExpression.factors))
                return
            }
            if(Number.isInteger(expression.factors[index]/divisor)){
                tempExpression.factors[index] = expression.factors[index]/divisor
                // console.log({tempExpression})
                setExpression(createExpression(tempExpression.factors))
                return
            }
            
        }

        setTimeout(() => {
            removeIncorrectDisk(newdiskID)
            
        }, 1000);
    }

    function removeIncorrectDisk(TheDiskID){
        // console.log({TheDiskID})
        // console.log({disks2Display})
        const disk = disks2Display.find((diskInd2d) => diskInd2d.diskID === TheDiskID )
    
    // console.log({disk})
    
      const multiple = COLOUR2MULTIPLE[disk.colour]
      // console.log('checkAndSetAwards award_colour_btns?');
    
      freeDependencies(disk.position)
      setCardNum(prevCardNum => prevCardNum /multiple)
    
        
      setDisks2Display((oldDisks2Display) => oldDisks2Display.filter((diskInd2d) => diskInd2d.diskID !== TheDiskID))
    
    }


    useEffect(() => {
        if (disks2Display.length > 0){
            const lastDisk = disks2Display[disks2Display.length - 1]  
                // console.log({lastDisk, disks2Display})                      
            if(lastDisk.diskID === newDiskID)
            {

                reduceExpression(COLOUR2MULTIPLE[lastDisk.colour], lastDisk.diskID)
            }
        }
    }, [disks2Display, newDiskID])
    
    

    useEffect(() => {
        // console.log({expression})#
        if (expression.amtChars > 0){
            setIsColourBtnActive(true)
        }
        else{
            setIsColourBtnActive(false)
        }
    },[expression])


    useEffect(() => {
        // console.log({areDataIdsReady})
        if(areDataIdsReady && factoriseExpressions.length > 0){
          setIsFactExpStarted(true)
        setUpCalculate()            
        }

    }, [areDataIdsReady])

function setUpNextNumber() {
  setCardNum(1)
  displayCardNum(1)
  setClickedNumbers(oldClickedNumbers => [])
  setUpCalculate()
  setCardFlipped(false)
}

//   const titleStyle = {
//     padding: 'auto',
//     color: 'white',
//     fontSize: `${appSize/14}px`,
//     alignSelf: 'center'
// }

    return (
      <div className="App">

        

        <Container fluid> 
       
          <Row>
            <Col><Logo /></Col>
            

              <Col></Col>
              <Col 
              sm={IsAspectRatioLandscape ? 4 : 10} 
              style={{
                padding: 'auto',
                color: 'white',
                fontSize: `${appSize/14}px`,
                alignSelf: 'center'
            }}
              >Convert to Colours</Col>
          </Row>

          <Row className="mt-5">
            <Col
                style={{
                    color: 'white',
                    fontSize: `${appSize/27}px`
                }}
            >
            <p>Score</p>
            <p>{score}</p>    
            </Col>        
            <Col 
               style={{ 
                padding: "auto", 
                height: appSize}}
              sm={IsAspectRatioLandscape ? 5 : 10}
              >
              {expression.amtChars > 0 ? 
              <Card
                style={{
                    padding: '3%',
                    backgroundColour: 'black'
                }}
              >
                <Row>
                   <Col
                   
                   style={{
                    backgroundColour: 'white',
                    fontSize: 'large'
                
                }}
                   >
                    <h3>Convert the number or number phrase shown into colours.</h3>
                    <p>Put disks in to make the value of the expression shown. As the right disks go in, the expression will change so that the card represents the same number as a combination of colours and the expression.</p>
                    <p>Once you've converted the card into just colours, find the number using the hundred square</p>
                   </Col> 
                   <Col
                   >
                    <img 
                        src={'images/PrimeFact.gif'} 

                        width='100%'
                        />
                   </Col>
                </Row>
                
              </Card> 
              : 
              <HundredSquare  
                // className='tour-numBtns'
                
                cardNum = {cardNum}

                // clickedNumbers = {clickedNumbers}
                // foundNumbers = {foundNumbers}
                onNumberClicked = {(value) => {
                  // console.log('dailyStreakUpdated', dailyStreakUpdated);
                    setClickedNumbers(oldClickedNumbers => [...oldClickedNumbers, value])
                    setNumberOfBtnClicks(oldNumberOfButtonClicks => oldNumberOfButtonClicks + 1)
                    const timestamp = Timestamp.fromDate(new Date(Date.now()))
                    const dataLabel = 'NumberClicked@(s)'+timestamp.seconds
                    const data = {}
                    dashLog[dataLabel] =  {
                      cardNum, 
                      numberClicked: value
                    }
                    updateDataLogger(dashLog, ['sessionLog'], dataLogger);
          
                    // console.log('cardNum, value', dataLog[dataLabel])
                    // console.log('foundNumbers', foundNumbers.length)        

                    // console.log('docTrack', sessionDocId)
                    // setDoc(doc(db, sessionDocId), dataLog, {merge: true})       
                    const tempFailedAttempts = failedAttempts
                    tempFailedAttempts[cardNum] =  tempFailedAttempts[cardNum] + 1 

                    if (value===cardNum){
                      
                      setCardFlipped(true)

                      setTimeout(function(){
                        setUpNextNumber()
                    }, 1600)
                      // console.log('tempNumTimesFound', tempNumTimesFound)
                      setIntroNumbers(oldIntroNumbers => [...oldIntroNumbers, value])
                      if( userID !== 'guest'){        
                    //   updateDataLogger(data, ['userLog'], dataLogger)
                        // console.log('docTrack',  'users/'+userID, data)
                        // setDoc(doc(db, 'users/'+userID), data, {merge: true})
                      }        
                      // setDoc(doc(db, dashDocId), dashLog, {merge: true})
                    //     updateDataLogger(dashLog, ['dashLog'], dataLogger)

                    //   updateDataLogger(data, ['sessionLog'], dataLogger)
                      // console.log('docTrack', sessionDocId)
                      // setDoc(doc(db, sessionDocId), data, {merge: true});
                    // console.log('stability: ', stability);

                    }
                    // console.log('forgetCurve: ', forgetCurve);
                    // console.log('NUMBER_STABILITY_BASE: ', NUMBER_STABILITY_BASE, 'numberStability[value]', numberStability[value], numberStability);
                      setFailedAttempts(tempFailedAttempts);
                }}
              />}
              </Col>
              <Col>    

              </Col>
              <Col sm={IsAspectRatioLandscape ? 4 : 10}>
              <PCcard 
                cardNum = {cardNum}
                isColourBtnActive = { isColourBtnActive }
                cardSize = {appSize}
                onDiskClicked = {(diskID) => {
                //   const disk = disks2Display.find((diskInd2d) => diskInd2d.diskID === diskID )
                //   const multiple = COLOUR2MULTIPLE[disk.colour]
                //   // console.log('checkAndSetAwards award_colour_btns?');

                //   checkAndSetAwards('award_colour_btns')
                //   freeDependencies(disk.position)
                //   setCardNum(prevCardNum => prevCardNum /multiple)
                //   setClickedNumbers(oldClickedNumbers => [])
                //   setNumberOfCardNumChanges(oldNumberOfCardNumChanges => oldNumberOfCardNumChanges + 1)

                  
                //   setDisks2Display((oldDisks2Display) => oldDisks2Display.filter((diskInd2d) => diskInd2d.diskID !== diskID))

                }}
                onColourBtnClicked = {(multiple, colour) => {

                  // console.log('multiple', multiple)
                  
                    if (multiple*cardNum > numberRange.high ||  multiple*cardNum < numberRange.low) return
                    setNumberOfCardNumChanges(oldNumberOfCardNumChanges => oldNumberOfCardNumChanges + 1)
                    setCardNum(prevCardNum => prevCardNum * multiple)
                    const tempNewDiskID = colour + (new Date).getTime()
                    setNewDiskID(tempNewDiskID)


                    setDisks2Display((oldDisks2Display) => {
                        
                        const position = randomFreePosition()
                        const newDisk = {
                            diskID: tempNewDiskID,
                            colour, 
                            position, 
                            gridSize: GRID_SIZE                        
                        }
                        return [...oldDisks2Display, newDisk]
                        } )
                        
                
                        // reduceExpression(multiple, newDiskID)

                    
                    // setCardNum(prevCardNum => prevCardNum * multiple)
                    
                    // setDisks2Display((oldDisks2Display) => {
                    //     const diskID = colour + (new Date).getTime()

                    //     const position = randomFreePosition()
                    //     const newDisk = {
                    //       diskID,
                    //       colour, 
                    //       position, 
                    //       gridSize: GRID_SIZE                        
                    //     }
                    //   return [...oldDisks2Display, newDisk]
                    // })
                    setClickedNumbers(oldClickedNumbers => [])
                  
                }}
                expression = {expression}
              />
              </Col>
              <Col></Col>
          </Row>
          <Row className='mt-5'>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col></Col>
            <Col
                style={{
                    position: 'relative'
                }}
            ><img 
            src={'images/numCards.png'} 
            style={{
                position: 'absolute',
                paddingTop: '10%'

            }}
            width='80%'
            
            /><span
                      style={{
                        padding: '15%',
                        fontSize: `${appSize/12.2}px`,
                        textAlign: 'center',
                        color: 'black',
                        position: 'absolute',
                        left: '43%',
                        top: '-20%'
                      }}
            >{factoriseExpressions.length + 1}</span></Col>
            <Col><Button
             variant="primary" size="lg"
              onClick={setUpNextNumber }
            >Skip This</Button></Col>
            <Col></Col>
            

        </Row>

          
        </Container>           
      </div>//for App

    );
}
