import React, { useRef, useEffect } from 'react'
const CanvasBar = ({current, total}) => {
  const canvasRef = useRef(null) 
    // console.log({current, total})
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        // console.log(context.canvas.width, current * context.canvas.width / total)
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
       
        context.fillStyle = '#00ffff'
        context.fillRect(3, 0, context.canvas.width, context.canvas.height)

        context.fillStyle = 'green'
        context.fillRect(3, 10, current * context.canvas.width / total, context.canvas.height - 20)

        // let frameCount = 0;
        // let animationFrameId;
        // const render = () => {
        // //   frameCount++;
        //   draw(context);
        //   animationFrameId = window.requestAnimationFrame(render);
        // };
        // render();
        // return () => {
        //   window.cancelAnimationFrame(animationFrameId);
        // };
      }, [current]);
      // console.log('stats', current, total)


  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.strokeStyle = 'green';
    ctx.fillStyle = 'pink';
    ctx.rect(0,0,total,10)
    ctx.stroke()
    ctx.rect(0,0,current,10)
    ctx.fill()

  };
  
  return <canvas style={{ width: '99%', height: '30px' }} ref={canvasRef} />;
}
export default CanvasBar