import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './hooks/useCountdown';
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import RedPillBluePill from './RedPillBluePill';
import { useApp } from './contexts/AppContext'
import { Link, useNavigate } from "react-router-dom"
import { doc, setDoc } from "firebase/firestore"
import { db } from "./firebase"
import { useMessaging } from './hooks/useMessaging'

// const RedPillBluePill = () => {
//   const { 
//     setHelpModeOn,
//     userID,
//     earnedNumbers,
//     testNumbers,
//     timeUntilNextSession, timeOfLastNewFind,
//     hasStarted, setHasStarted, goToOutro, setGoToOutro,
//     memoryEstimate, awards, setExitLink
//   } = useApp() 
//   const prevCardsFound = earnedNumbers.filter(num => !isNaN(num)).length
//   const [ mission, missionOptions, supportVids] = useMessaging(prevCardsFound, memoryEstimate, awards, userID) 
//   const navigate = useNavigate()


//   function missionAction(keyword){

//     console.log('keyword', keyword)

//     if (keyword === 'scores'){
//       setExitLink({path: '/', text: 'Mission'})
//       navigate('/ScoreCard')
//       return
//     }
//     if (keyword === 'login'){
//       navigate('/login')
//       return
//     }
//     if (keyword === 'explore'){
//       setHasStarted(true)
//       // console.log('testNumbers', testNumbers.length)
//       setGoToOutro(true);
//       navigate("/explore")
//       return
//     }
//     if (keyword === 'guidedTour'){
//       startHelpedSession()
//       return
//     }  if (keyword === 'flash'){
//       startSession()
//       return
//     }  if (keyword === 'checkScores'){
  
//       return
//     }
  
//   }
//       function startSoloSession() {
//         setHelpModeOn(false)
//         startSession()
//       }
  
//       function startHelpedSession() {
//         setHelpModeOn(true)
//         setDoc(doc(db, 'users/'+ userID), {helpOn: true}, {merge: true})

//         startSession()
//       }
  
//   function startSession() {
//     setHasStarted(true)
//     // console.log('testNumbers', testNumbers.length)
//     setGoToOutro(true);
//     // console.log('goToOutro', goToOutro)
//     if (testNumbers.length > 0){
//       navigate("/flashTest")
//     }
//     else {
//     navigate("/explore")          
//     }

//   }
//     // console.log('missionOptions', missionOptions)  

//   return (

//     <Container>
//       <Row 
//         style={{ color: "black" }}
//       >
//       <Col>
//         <h3>Your Choices</h3>
//         </Col>
//       </Row>
//       <Row 
//           className="mb-5"
//           style={{ color: "white" }}>
//             <Col md={2}></Col>
//             <Col md={3}>
//           <Button 
//           className="w-100"
//           style={{ backgroundColor: "red",
//           borderRadius: "40%"    
//                 }}
//           onClick={() => missionAction(missionOptions.red.action)}>{ missionOptions.red.label }</Button>                 
//             </Col>
//             <Col md={2}></Col>              
//             <Col md={3}>
//             <Button 
//           className="w-100"
//           style={{ 
//             backgroundColor: "blue",
//             borderRadius: "40%"    
                  
//           }}
//           onClick={ () => missionAction(missionOptions.blue.action) }>{ missionOptions.blue.label }</Button> </Col>
//             <Col md={2}></Col>
//           </Row>
//     </Container>
//   )
// }

/*
    (numbersFound < 4) ? (
        <Row 
          className="mb-5"
          style={{ color: "white" }}>
            <Col md={2}></Col>
            <Col md={3}>
          <Button 
          className="w-100"
          style={{ backgroundColor: "red",
          borderRadius: "40%"    
                }}
          onClick={startSoloSession}>{"I'll figure it out for myself"}</Button>                 
            </Col>
            <Col md={2}></Col>              
            <Col md={3}>
            <Button 
          className="w-100"
          style={{ 
            backgroundColor: "blue",
            borderRadius: "40%"    
                  
          }}
          onClick={startHelpedSession}>{'I would like some guidance'}</Button> </Col>
            <Col md={2}></Col>
            </Row>
        ) : (
          <Row 
          className="mb-5"
          style={{ color: "white" }}>
            <Col md={3}></Col>
            <Col md={6}>
          <Button 
          className="w-100"
          onClick={startSession}>{welcomeMessage.button}</Button>                 
            </Col>
            <Col md={1}></Col>
            </Row>
        )

*/


// // ExpiredNotice = ({numbersFound, startSession, startHelpedSession, startSoloSession, welcomeMessage} )
// const ExpiredNotice = ({ missionOptions, missionAction } ) => {
//     // console.log('missionOptions', missionOptions)
//     // console.log('numbersFound', numbersFound)
//   return (
//       <RedPillBluePill
//       missionOptions={ missionOptions } />
//   );
// };

const ShowCounter = ({ hours, minutes, seconds }) => {
  return (
    <div className="show-counter">

        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
     
    </div>
  );
};

const ShowNextSession = ({ targetDate }) => {
  const displayDate = new Date(targetDate).toLocaleString();

  return(
    <div className="show-counter">
      <DateTimeDisplay value={displayDate} type={'Date/Time Next Available'} isDanger={false} />
    </div>
  );
}
//CountdownTimer = ({ targetDate, numbersFound, startSession, startHelpedSession, startSoloSession, welcomeMessage })
const CountdownTimer = ({ targetDate }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate);
// console.log('welcomeMessage', welcomeMessage)
  // console.log('targetDate', new Date(targetDate))
// console.log('numbersFound', numbersFound)
// console.log('Time Left', hours, minutes, seconds)
  if (hours + minutes + seconds <= 0) {
    // console.log('missionOptions', missionOptions)

  
    return <RedPillBluePill />;
  } else {
    return (
      
      <Row>
        <Col>
      <ShowNextSession targetDate = {targetDate} />
      </Col><Col>
      <ShowCounter
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      /></Col>
      </Row>
    );
  }
};

export default CountdownTimer;