import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap';
import PCcard from "./PCcard";
import { db } from "./firebase"
import { doc, query, where, addDoc, setDoc, collection, getDocs, serverTimestamp, Timestamp, getDoc } from "firebase/firestore"


export default function Dash() {
    // console.log('Dash render')

    const [dataObject, setDataObject] = useState({})
    const [userDataOutput, setUserDataOutput] = useState()
    const currentTimestamp = Date.now()
    const todayDateObj = getDateObject(Date.now())
    const yesterdayDateObj = getDateObject(new Date(currentTimestamp - 86400000*7))
    // console.log('todayobj', todayDateObj, 'yester', yesterdayDateObj)
    const yesterdaysFBTimestamp = Timestamp.fromDate(new Date(currentTimestamp - 86400000*7))
    const yesterdaysTimestamp = yesterdayDateObj.millisecs


    const dateRange = () => {
        const todaydate = new Date(currentTimestamp)
        const yes2daydate = new Date(yesterdaysTimestamp)
        return yes2daydate.toDateString()+ " - " + todaydate.toDateString()
    }

    function getDateObject(givenDate){
        const returnObj = {}
        const todaysDate = new Date(givenDate)
        const monthNumber = todaysDate.getMonth()+1
        const dateNumber = todaysDate.getDate()
        const hourNumber = todaysDate.getHours()
        const monthDigits = (monthNumber > 9) ? monthNumber : '0'+monthNumber
        const dateDigits = (dateNumber > 9) ? dateNumber : '0'+dateNumber
        const hourDigits = (hourNumber > 9) ? hourNumber : '0'+hourNumber
        const dateAsString = todaysDate.getFullYear()+' '+monthDigits+' '+dateDigits+' '+hourDigits
        returnObj['dateAsKey'] = dateAsString.replace(/\s+/g, '')
        const timestamp = Timestamp.fromDate(todaysDate)
        returnObj['millisecs'] = Math.round((timestamp.seconds*1000000000 + timestamp.nanoseconds)/1000000)
        return returnObj
      }

    useEffect(() => {


        const sessionColRef = collection(db, 'dashdata')

        const q = query(sessionColRef, where('MillisecStarted', '>', yesterdayDateObj.millisecs))
        getDocs(q).then((snapshot) => {
            const tempObj = {
                numSessions: 0,
                btnClicks: 0,
                colourClicks: 0,
                finds: 0, 
                lastSession: 0
            }
            const userData = { }
            console.log('snapshot.docs', snapshot.docs)
            snapshot.docs.forEach((doc) => {

                // console.log('userIds', doc.data())
                doc.data().userIds.forEach(element => {
                    if (!userData[element]){
                        userData[element] = {
                            numSessions: 0,
                            btnClicks: 0,
                            colourClicks: 0,
                            finds: 0, 
                            lastSession: 0
                        }
                    }
                    let count = 0
                    if (doc.data().sessions){
                        Object.values(doc.data().sessions).forEach(sessionElement => {
                            if (element === sessionElement) count = count + 1 
                        });
                    }
                    const userBtnKey = 'btnClicks@'+element
                    const userColourKey = 'colourBtnClicks@'+element
                    const userFindsKey = 'finds@'+element
                    const userMillisecs = 'MillisecStarted';
                    tempObj.btnClicks = ((doc.data()[userBtnKey]) ? doc.data()[userBtnKey] : 0)+ tempObj.btnClicks
                    tempObj.colourClicks = ((doc.data()[userColourKey]) ? doc.data()[userColourKey] : 0) + tempObj.colourClicks
                    tempObj.finds = ((doc.data()[userFindsKey]) ? doc.data()[userFindsKey] : 0) + tempObj.finds
                    tempObj.numSessions = count + tempObj.numSessions
                    userData[element].numSessions = count + userData[element].numSessions
                    userData[element].btnClicks = ((doc.data()[userBtnKey]) ? doc.data()[userBtnKey] : 0) + userData[element].btnClicks 
                    userData[element].colourClicks = ((doc.data()[userColourKey])? doc.data()[userColourKey] : 0) + userData[element].colourClicks
                    userData[element].finds = ((doc.data()[userFindsKey]) ? doc.data()[userFindsKey] : 0) + userData[element].finds
                    userData[element].lastSession = ((doc.data()[userMillisecs] > userData[element].lastSession) ? doc.data()[userMillisecs] : userData[element].lastSession) 
                    // console.log('userData', userData)
                });
                // const thisSession = []
                // const userActivity = []
                // const guestActivity = []
                // console.log('numbers', numbers)
                // console.log('doc.data()', doc.data())
                // numbers.forEach(element => {
                //     if(!isNaN(element)){
                //         thisSession.push(element)
                        
                //     }
                // })                
                // tempObj.numbers = [...new Set(thisSession)]
            //     if (doc.data().userDoc == "guest"){
            //         tempObj.guests = tempObj.guests + 1
            //         guestActivity.push(thisSession)
            //     }
            //     else {
            //         tempObj.userIDs.push(doc.data().userDoc)
            //         userActivity.push(thisSession)

            //     }                
            // tempObj.userActivity = [...new Set(userActivity)]
            // tempObj.guestActivity = [...new Set(guestActivity)]

            })   
            // console.log('tempObj', tempObj)
            setDataObject(tempObj)
            // console.log('userObj', userData)
            setUserDataOutput(userDataOutputFunction(userData))
        })
}, [])

function userDataOutputFunction(dataObj){
    let output =""
    Object.keys(dataObj).forEach(element => {

        output = output + `<tr> <td>${element}</td><td>${Date(dataObj[element].lastSession)}</td><td>${dataObj[element].numSessions}</td><td>${dataObj[element].btnClicks}</td><td>${dataObj[element].colourClicks}</td><td>${dataObj[element].finds}</td></tr>`
        // console.log('output', output)
    });
    return output
}
  return (
      <Card style={{
        top: "25%",
        left: "25%",
        height: "50%", 
        width: "50%",
        position: "absolute", 
        backgroundColor: "gray",
        zIndex: "40"
      }}>
          <h1>Dashboard</h1>
    <h2>Data for {dateRange()}</h2>
    <table>
        <tr>
            <th>UserID</th><th>Last session</th><th>Number of sessions</th><th>buttons clicked</th><th>colourBtns clicked</th><th>numbers found</th>
        </tr>
        <tr>
            <td>Totals</td><td>N/A</td><td>{dataObject.numSessions}</td><td>{dataObject.btnClicks}</td><td>{dataObject.colourClicks}</td><td>{dataObject.finds}</td>
        </tr>
        <tbody dangerouslySetInnerHTML={{__html: userDataOutput}}>
            </tbody>  
    </table>

      </Card>

  )
}
