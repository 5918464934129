import styled from 'styled-components'

// console.log('NumBtnstyled render')


const NumBtnStyled = styled.div`
    font-size: ${props => props.appSize/30}px;
    border: ${props => props.isSecured ? '4px solid green' : props.isFoundNumber ? '4px solid black' : '1px dotted black'};
    justify-content: center;
    position: relative;
    border-radius: 4%;
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, .3);
    background-color: ${props => props.isFlipped ?  'red' :props.isFoundNumber ? props.isCorrectAnswer ? 'yellow' : 'rgb(250, 250, 247)' : `rgba(250, 250, 247, ${props.btnOpacity})` }; 
    transform-style: preserve-3d;
    cursor:  ${props => props.isFlipped ? 'not-allowed' : 'pointer'}   ;
    width: ${props => props.appSize/14}px;
    height: ${props => props.appSize/14}px;
    transition: 200ms;
    transform: ${props => props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)'} perspective(1000px);
    &.num-btn-on:hover  {
        font-size: ${props => props.appSize/22}px;
        width: ${props => props.appSize/11}px;
        height: ${props => props.appSize/11}px;
        font-weight: bold;
        transform: translateX(-5px) translateY(-5px);
        box-shadow: 0 0 7px 3px rgba(255, 255, 255, 0.467);
        z-index: 30;
    }
`

export default NumBtnStyled
