import styled from 'styled-components'

// console.log('ColourBtnStyled render')

// ColourBtn is a prop so how does it get used to generate the different colours in a styled Component?
const ColourBtnStyled = styled.div`
    top: ${props => props.colourBtn.top ? props.colourBtn.top : 4}%;
    left: ${props => props.colourBtn.left}%;
    background-color: ${props => props.colourBtn.colour};
    border-radius: 50%;
    height: ${props => props.cardSize/22}px;
    width: ${props => props.cardSize/22}px;
    position: absolute;
    z-index: 25;
    .active {
    cursor: pointer;
    }
&:hover.active {
    top: ${props => props.colourBtn.hoverTop ? props.colourBtn.hoverTop : -3}%;
    height: ${props => props.cardSize/15}px;
    width: ${props => props.cardSize/15}px;
}


`

export default ColourBtnStyled