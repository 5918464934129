import React from 'react'
import StaticPCcard from './StaticPCcard'
import { useApp } from './contexts/AppContext'

export default function PackOfCards({packArray, layoutDimensions, clickedCards, foundCards, isNumberClickable, onCardClicked}) {
    const { appSize, appWidth, appHeight, packIsUnshuffled } = useApp()

    while (packIsUnshuffled){
    // console.log({foundCards, packIsUnshuffled})
      return (<></>)
    }



  return (
    <div
    style={{
                
        display: 'block',
        position: 'relative',
        // alignItems: 'center',
        // gridTemplateColumns: `repeat(${layoutDimensions[0]}, ${appWidth*0.8/(layoutDimensions[0])}px)`,
        // gap: `${appWidth/180}px`

}}>
    {packArray.map((value, index) => {
      // console.log({clickedCards})
        return (
          
            <StaticPCcard 
            cardNum = {value} 
            cardSize = {(appWidth*0.8/layoutDimensions[0] > appHeight*0.7/layoutDimensions[1]) ? appHeight*0.7/layoutDimensions[1] : appWidth*0.8/layoutDimensions[0]}
            key = {value + '_' + index}
            cardID = {value + '_' + index}
            isHidden = { foundCards.includes(value + '_' + index) }
            left = {(index%layoutDimensions[0])*(appWidth*0.8/(layoutDimensions[0]))}
            top = {Math.floor(index/layoutDimensions[0])*(appWidth*0.8/(layoutDimensions[0]))}
            isNumberClickable = {isNumberClickable}
            isCardFlipped = {clickedCards.includes(value + '_' + index)}
            onClick={() => onCardClicked(value + '_' + index)}
            onDiskClicked = {(diskID) => {
              // console.log({diskID})
            }}
            expression = { false }
            />
        )
    })}
        
    </div>
  )
}
