import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { db } from "./firebase"
import { doc, setDoc, Timestamp, getDoc } from "firebase/firestore"
import { useApp } from './contexts/AppContext'
import PCcard from './PCcard'
import SurveyOption from './SurveyOption'

export default function SurveyQuestion({jsonQuestion, questionNum}) {

  const { 
    userID, dateObject, appSize, GRID_SIZE, pcArray,setDisks2Display, getFreePositionsByNum, updateDataLogger, dataLogger
  } = useApp()   

 const [comment, setComment] = useState('')
 const [chosenOption, setChosenOption] = useState('')
 const [cardPhrase, setCardPhrase] = useState({immediate: 'I can remember this immediately',
                                              quickCalc: 'I can calculate this quickly',
                                            longCalc: 'I can calculate this but slowly',
                                          nothing: 'I can\'t work this out'}) 
  // console.log('jsonQuestion', jsonQuestion, jsonQuestion.options)

  function amtOfDisks( pcNumArray){
    let returnVal = 0
    // console.log('pcNumArray', pcNumArray)
    for (let index = 2; index < pcNumArray.length-1; index++) {
        // console.log('pcNumArray[index]', pcNumArray[index])
        returnVal = returnVal + pcNumArray[index]
    }
    return returnVal 
}

function displayCardNum(num){
    // console.log('pcArray[num]', pcArray[num], num)
    const colourArray = ['black', 'white', 'red', 'green', 'blue', 'purple', 'yellow', 'orange']
    setDisks2Display([])
    const positions = getFreePositionsByNum(amtOfDisks(pcArray[num]))
    // console.log('positions', positions)
    let count = 0
    for (let index = 2; index < pcArray[num].length-1; index++) {
        const numDisks = pcArray[num][index];
        const colour = colourArray[index]

        for (let j = 0; j < numDisks; j++) {
            // console.log('colour', colour)
            setDisks2Display((oldDisks2Display) => {
                const diskID = colour + count + (new Date).getTime()
                // console.log('diskID', diskID)
                
                const newDisk = {
                  diskID,
                  colour, 
                  position: positions[count%positions.length], 
                  gridSize: GRID_SIZE                        
                }
                count++
                // console.log('newDisk', newDisk)
              return [...oldDisks2Display, newDisk]
            })
            
        }
        
    }
}

useEffect(() => {
  setComment('')
  setChosenOption('')
}, [questionNum])

useEffect(() => {
  if (jsonQuestion.isCard){
    displayCardNum(jsonQuestion.cardNum)
    // console.log('jsonQuestion', jsonQuestion)
  }

}, [jsonQuestion.isCard, jsonQuestion.cardNum])

useEffect(() => {
      updateDataLogger(comment, ['feedbackLog','session@(ms)' + dateObject.millisecs, questionNum, 'comment'], dataLogger);

}, [comment])

//   function getData(val){
//     console.log(val.target.value)
//     const comment = val.target.value

//  }

  function processChoice(chsnOpt){
    // console.log('chsnOpt', chsnOpt)
    // const datakey = dateObject.millisecs
    setChosenOption(chsnOpt)
    let questionData = ''

      questionData = (jsonQuestion.isCard) ? jsonQuestion.questionText + ' ' + jsonQuestion.cardNum : jsonQuestion.questionText
 
    const data = {
                  user: userID, 
                  question: questionData, 
                  chosenOption: chsnOpt
                }

                // console.log('processChoiceData', data)
    updateDataLogger(data, ['feedbackLog', 'session@(ms)' + dateObject.millisecs, questionNum], dataLogger);

    // setDoc(doc(db, 'feedback/'+ dateObject.hourAsKey), data, {merge: true})
  }


  return (
    <Container>
        <Row>
          <Col>
            <h3>{jsonQuestion.questionText}</h3>
            {(chosenOption !== '') ? <><img src={`images/icons/${chosenOption}.png`} style={{alignContent: 'center'}} height='30%' />{(jsonQuestion.isCard) ? <p style={{textAlign: 'center', fontSize: 'x-large', fontWeight: 'bold'}}>{cardPhrase[chosenOption]}</p> : <p style={{textAlign: 'center', fontSize: 'x-large', fontWeight: 'bold'}}>I feel {chosenOption}</p>}</> : <></>}
          </Col>
          <Col style={{height: `${appSize*0.4}px`}}>
          {(jsonQuestion.isCard) ? 
                <PCcard 
                    cardNum = {jsonQuestion.cardNum}
                    cardSize = {appSize*0.4}
                    onDiskClicked = {(diskID) => {
                        // console.log('onDiskClicked', diskID)

                    }}
                    onColourBtnClicked = {(multiple, colour) => {
                    // console.log('onColourBtnClicked', colour, multiple)
                    }}
                    isColourBtnActive = { false }
                    
                /> :
          <img src={`images/icons/${jsonQuestion.questionImage}.png`} height='100%'  /> }
          </Col>
        </Row>
        <Row className='mt-3'>
            {
            jsonQuestion.options.map((option, i) => <Col key={i}>
                    <SurveyOption
                    onClick={() => processChoice(option)}
                    optionType={jsonQuestion.optionType} 
                    optionText={option} 
                    isOptionChosen={(chosenOption === option)}
                    />
                </Col>
            )}
        </Row>
        <Row><Col>Comments or observations</Col></Row>
        <Row><Col><textarea
            style={{width: "100%", height: "100px"}}
            onChange={(e) => setComment(e.target.value )}
            value={comment}
         /></Col>
         </Row>
         
    </Container>
  )
}
