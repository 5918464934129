import React, { useEffect } from 'react'
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { useApp } from './contexts/AppContext'
import { Link, useNavigate } from "react-router-dom"
import YouTubeEmbed from "./YouTubeEmbed";
import { db } from "./firebase"
import { doc, setDoc } from "firebase/firestore"
import CountdownTimer from './CountdownTimer';
import { useMessaging } from './hooks/useMessaging'
import Logo from './Logo'
import RedPillBluePill from './RedPillBluePill'

export default function Intro() {
    const { 
        setHelpModeOn,
        userID,
        earnedNumbers,
        testNumbers,
        timeUntilNextSession, timeOfLastNewFind, timeOfLastFlashTest,
        hasStarted, setHasStarted, goToOutro, setGoToOutro,
        maxScore, memoryEstimate, awards
      } = useApp() 
      const { currentUser, handleLogout } = useAuth()
    const navigate = useNavigate()
    const numFont = {
      fontSize: "50px",
      fontWeight: "700",
      textAlign: "center"
  }

    const prevCardsFound = earnedNumbers.filter(num => !isNaN(num)).length
    // console.log('timeOfLastFlashTest', timeOfLastFlashTest);
    const [ mission, missionOptions, supportVids] = useMessaging(prevCardsFound, memoryEstimate, awards, userID);
    const timeNow = new Date(Date.now());
    const targetDate = timeOfLastNewFind + 7*60*60*1000
    // console.log('mission', mission)
//     const [prevCardsFound, setPrevCardsFound] = useState(earnedNumbers.filter(num => !isNaN(num)).length)

// console.log('earnedNumbers', earnedNumbers.filter(num => !isNaN(num)).length, earnedNumbers)
// console.log( 'prevCardsFound', prevCardsFound)
        // console.log('maxScore', maxScore)

const SupportVideos = ({ video1, video2, video3, width }) => {
  // console.log('videos',video1, video2, video3 )
  // console.log('video.title', video1.title)
  return (
    <Container>
        <Row>
            <Col>{(video1) ? <>{video1.title}<YouTubeEmbed 
            embedId={video1.video}
             width={ width } 
             /></> : <></>}
             </Col>
            <Col>{(video2) ? <>{video2.title}<YouTubeEmbed 
            embedId={video2.video}
             width={ width } 
             /></> : <></>}
             </Col>
            <Col>
            {(video3) ? <>{video3.title}<YouTubeEmbed 
            embedId={video3.video}
             width={ width } 
             /></> : <></>}
             </Col>
        </Row>
    </Container>
  )
}



const Mission = () => {
  // console.log('missionText', missionText)
  return(
    <Row 
            className="mb-2">
            <Col md={1}></Col>
            <Col md={10}><p 
                className="welcome" 
                style={{
                  fontSize: 'x-large',
                  textAlign: 'center'
                }}>
                  {mission}
                </p>
                
                  
                  </Col>
            <Col md={1}>

            </Col>
          </Row>    
  )
}



  // function welcomeMessage(){
  //       const msg = {
  //         heading: '',
  //         challenge: '',
  //         info: '',
  //         button: ''
  //       }
  //       msg.heading = TITLES[getvideo()]
  //       msg.button = currentUser ? 'Get Started' : 'Click Here to Start Playing'
  //       // msg.video = currentUser ? '' : '<iframe width="280" height="158" src="https://www.youtube.com/embed/lG2MdfuPJ5o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  //       msg.challenge = CHALLENGES[getvideo()]
  //       msg.info = "The colours show the connections and patterns between numbers.  As you learn to see numbers this way, you can start to play with numbers differently."
  //       return msg
  //     }

  
// console.log('welcomeMessage', welcomeMessage())
  return (
<div className="App">
      <Logo />

      <Container fluid="xxl"> 
        <Card style={{
            // visibility: hasStarted ? "hidden" : "visible",
            padding: "2%",
            top: "20%",
            left: "20%",
            width: "60%",
            position: "absolute", 
            borderRadius: "4%",
            backgroundImage: "url('images/multiplicative.png')",
            backgroundSize: "100%",
            backgroundColor: "transparent",
            zIndex: "40"
          }}>
            {/* {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? (
              <>
                <p>It seems like you're using a mobile device.  Unfortunately this app doesn't work on mobile devices yet!</p>
                <p>Please try on a desktop or laptop.  Sorry for the inconvenience.</p>
              </>
            ) : ( */}
              <Card.Body>
                <Row>
              <Col md={8}>
                <h2 className="mb-4">Your Mission...</h2>
                <Mission />
              </Col>
              <Col md={4}>
                <h3 className='mt-5' style={{textAlign: 'center'}}>{(maxScore > 2) ? 'Your Maximum Score This Session' : ''}</h3>
                <p style={numFont}>{(maxScore > 2) ? maxScore : ''}</p>
              </Col></Row>
            {(targetDate > timeNow) ? <CountdownTimer 
            targetDate={ targetDate }
            missionOptions={ missionOptions}
            /> : <RedPillBluePill />}

                  
          </Card.Body>
          <Card.Footer>
              <SupportVideos
                  video1={supportVids[0]}
                  video2={supportVids[1]}
                  video3={supportVids[2]}
                  width="200"
                  />
           
          </Card.Footer>


          </Card> 
        </Container>
      </div>  
  )
}
