import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"


export default function ForgotPassword() {
    //console.log('ForgotPassword render')

    const emailRef = useRef()
    const { resetPassword } = useAuth() 
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    
    async function handleSubmit(e) {
        e.preventDefault()

        try{

            setError("")
            setMessage("")
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage('Check your inbox for further instructions')
        } catch {
            setError("Failed to Reset Password")
        }

        setLoading(false)
    }



  return (
    <>
        <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "50vh"}}>
      <div className="w-100" style={{maxWidth: "400px"}}>

        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">
                    Password Reset
                </h2>
                {error && <Alert variant='danger'>{ error }</Alert>}
                {message && <Alert variant='success'>{ message }</Alert>}
                <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required></Form.Control>
                    </Form.Group>

                    <Button disabled={loading} className="w-100 mt-3" type="submit">Reset Password</Button>
                </Form>
                <div className="w-100 text-center mt-2">
                    <Link to="/login">Log In</Link>
                </div>

                <div className="w-100 text-center mt-2">
                    Need an account?  <Link to="/signup">Sign Up</Link>
                </div>
            </Card.Body>
        </Card>

        </div>  
                
                </Container>

    </>
  )

}
