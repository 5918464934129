import React, { useEffect, useState } from 'react'
import { Button, Alert, Container, Row, Col, Card } from 'react-bootstrap'
import PackOfCards from './PackOfCards'
import Logo from "./Logo";
import { useApp } from './contexts/AppContext';
import { useAuth } from './contexts/AuthContext';
import CanvasBar from './CanvasBar';
import { Link, useNavigate } from "react-router-dom"

export default function TestingScreen() {

  const {  appHeight, appWidth, appSize, IsAspectRatioLandscape, memoryEstimate, packArray, packIsUnshuffled, setExitLink } = useApp()
  const { currentUser } = useAuth()  

  const [clickedCards, setClickedCards] = useState([])
  const [foundCards, setFoundCards] = useState([])
  const [foundSets, setFoundSets] = useState([])
  const [isNumberClickable, setIsNumberClickable] = useState(false)
  const [sumOfCards, setSumOfCards] = useState(0)
  const [numOfCards, setNumOfCards] = useState(packArray.length)
  const [numOfBusts, setNumOfBusts] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    setNumOfCards(packArray.length)
    setExitLink({path: '/100orBust', text: '100 or Bust'})
  }, [packArray])

  function getCardSum() {
    let cardSum = 0
    for (let index = 0; index < clickedCards.length; index++) {
      const element = clickedCards[index];
      const cardNumber_IDArray = element.split("_")
      cardSum += parseInt(cardNumber_IDArray[0])
      
    }
    // console.log({cardSum})
    return cardSum
  }

  useEffect(() => {
    console.log({foundSets, numOfCards, packArray})
  }, [foundSets, numOfCards])

  useEffect(() => {

    const tempCardSum = getCardSum()
     setSumOfCards(tempCardSum) 
     if (tempCardSum > 99) {

      if (tempCardSum === 100) {
        setFoundSets(oldFoundSets => [...oldFoundSets, clickedCards.length])
        setTimeout(function(){
        setFoundCards(oldFoundCards => [...oldFoundCards, ...clickedCards])
        setNumOfCards(oldNum => oldNum - clickedCards.length)
        console.log('clickedCards.length',clickedCards.length, numOfCards )
          if (numOfCards === clickedCards.length){
            navigate("/explore") 
          }
        }, 2000)
      }          
        setTimeout(function(){
        
          setClickedCards([])
          setSumOfCards(0)          
          if(tempCardSum > 100){
            setNumOfBusts(oldNum => oldNum + 1)
            if (numOfBusts === 2){
              navigate("/explore") 
            }
          }

        }, 2500)


      }

  }, [clickedCards])

  function arrangeSquares(num, ratio) {
    // calculate the number of rows and columns needed to achieve the desired ratio
    let rows = Math.round(Math.sqrt(num / ratio));
    let cols = Math.round(rows * ratio) + 1;
    console.log({rows, cols})


    return [cols, rows]

  }

  return (

    <Container fluid> 
       
    <Row>
      <Col><Logo /></Col>
      

        <Col 
          style={{
              padding: 'auto',
              color: 'white',
              fontSize: `${appSize/14}px`,
              alignSelf: 'center'
          }}
        >100 or Bust</Col>
        <Col
      sm={IsAspectRatioLandscape ? 4 : 10}></Col>
    </Row>

    <Row className="mt-5">
    <Col xs lg={10}>        
          <CanvasBar
            current={getCardSum()}
            total={100}
          /></Col>
          <Col 
            xs lg={2}
            style={{fontSize: '100px', color: 'limegreen'}}
            >{(getCardSum() > 100) ? 'Bust' : '' }</Col></Row>
    <Row>
    <Col
          style={{
              color: 'white',
              fontSize: `${appSize/27}px`
          }}
      >
      {currentUser ? <>
      {(memoryEstimate > 1) ? <Link to='/scoreCard'><img src="images/statsButton.png" alt="button to stats page" width="80%" />Stats Page</Link> : <></>}


      </> : <></>
      }

      </Col>
      <Col 
         style={{ 
          padding: "auto", 
          height: appSize}}
        sm={10}
        >
        {(!packIsUnshuffled) ? <PackOfCards
          packArray={packArray}
          layoutDimensions={arrangeSquares(packArray.length, appWidth/appHeight)}
          clickedCards={clickedCards}
          foundCards={foundCards}
          isNumberClickable={isNumberClickable}
          onCardClicked={cardID => {
            console.log('clicked', cardID)
            clickedCards.includes(cardID) ? 
            (isNumberClickable) ? 
              setClickedCards(oldClickedCards => oldClickedCards.filter((element) => element !== cardID)) 
              : console.log()
            : setClickedCards(oldClickedCards => [...oldClickedCards, cardID])}}
        /> : <></>}
        </Col><Col></Col></Row>
        <Row>
  
        </Row>
        <Row>
          {
          foundSets.map((value, index) => {
          // console.log({clickedCards})
            return (
              <Col
              key={index}
              style={{fontSize: '50px', color: 'purple', textAlign: 'center', backgroundColor: 'grey', borderRadius: '50%'}}
              >{value}</Col>
            )
        })
        }
        <Col xs lg={12-foundSets.length}></Col></Row>
    </Container>
  )
}
