import styled from 'styled-components'

const ExpressionDisplay = styled.div`
    width: ${props => props.amtChars * 4 + 18}%; 
    height: 20%;
    top: ${props => Math.floor(props.position/props.gridSize)*(70/(props.gridSize-1)) + 7}%;
    left: ${props => (props.position%props.gridSize)*(70/(props.gridSize-1)) + 5}%;
    font-size: ${props => props.appSize/9}px;
    text-align: center;
    background: left top no-repeat scroll;
    background-image:  url("images/${props => props.amtChars}character.png");
    z-index: 27;
    background-size: 100% 100%;
    position: absolute;
    animation: fadeIn 1s;
    @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }

`

export default ExpressionDisplay