import React, {useRef, useState} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { Container } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { useApp } from './contexts/AppContext'
import { db } from "./firebase"
import { doc, query, where, addDoc, setDoc, collection, getDocs, serverTimestamp, Timestamp, getDoc } from "firebase/firestore"


export default function Feedback() {
    const easeRef = useRef()
    const learnRef = useRef()
    const detailsRef = useRef()
    const { currentUser } = useAuth() 
    // const [error, setError] = useState('')
    // const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { getDateObject } = useApp() 
    // console.log('Feedback render')



    function handleSubmit(e) {
        e.preventDefault()

        const dateObj = getDateObject()
        
        const dataLog = {

            user: currentUser.uid,
            ease: easeRef.current.value,
            learn: learnRef.current.value,
            details: detailsRef.current.value
           

        }

        setDoc(doc(db, 'feedback/'+ dateObj.millisecs), dataLog, {merge: true}) //change to unique key

        navigate('/')
    }

    return (
    
    <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "50vh"}}>
      <div className="w-200" style={{maxWidth: "400px"}}>

        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">
                    What do you think?
                </h2>
                
                {/* {error && <Alert variant='danger'>{ error }</Alert>} */}
                <Form onSubmit={handleSubmit}>
                <Form.Group id="ease">
                        <Form.Label>How easy is this app to use? 1(difficult) to 5(easy)</Form.Label>
                        <Form.Control type="number" max={5} min={1} ref={easeRef} required ></Form.Control>
                    </Form.Group>
                <Form.Group id="learn">
                        <Form.Label>How much do you feel you discovered? 1(nothing) to 5(a lot)</Form.Label>
                        <Form.Control type="number"  max={5} min={1} ref={learnRef} required ></Form.Control>
                    </Form.Group>
                    <Form.Group id="details">
                        <Form.Label>Could you add any details about your experience with this app?</Form.Label>
                        <Form.Control as="textarea" ref={detailsRef} rows={3}></Form.Control>
                    </Form.Group>
                    <Button className="w-100 mt-3" type="submit">Update Profile</Button>
                </Form>
        <div className="w-100 text-center mt-2">
            No Changes needed? <Link to="/login">Cancel</Link>
        </div>
            </Card.Body>
        </Card>

        </div>  
                
    </Container>

    
  )
}
