import React from "react";
import Dash from "./Dash";
import Signup from "./Signup";
import Login from "./Login";
import Intro from "./Intro";
import Outro from "./Outro";
import UpdateProfile from "./UpdateProfile";
import ForgotPassword from "./ForgotPassword";
import Feedback from "./Feedback";
import { AuthProvider } from "./contexts/AuthContext";
import { AppProvider } from "./contexts/AppContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Colours2Numbers from "./Colours2Numbers"
import FlashTest from "./FlashTest"
import Calculate from "./Calculate";
import ScoreCard from "./ScoreCard"
import Countdown from "./Countdown"
import ExitSurvey from "./ExitSurvey";
import PrivateRoute from "./PrivateRoute";
import 'bootstrap/dist/css/bootstrap.min.css';
import WeeklyActivity from "./WeeklyActivity";
import TestingScreen from "./TestingScreen";
import HundredOrBust from "./100orBust";

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   console.log('wdyr')
//   whyDidYouRender(React, {
//     include: [/HundredSquare/, /NumBtn/], logOnDifferentValues: true
//     // trackAllComponents: true,
//   });
// }

function App() {
  // console.log('App render')

  return (
        <Router>
          <AuthProvider>
            <AppProvider>
              <Routes>
                <Route exact path="/" element={ <Intro /> } />
                <Route  path="/testing-comps" element={ <TestingScreen /> } />
                <Route  path="/explore" element={ <Colours2Numbers /> } />
                <Route  path="/flashTest" element={ <FlashTest /> } />
                <Route  path="/100orBust" element={ <HundredOrBust /> } />
                <Route  path="/calculate" element={ <Calculate /> } />
                <Route path="/ScoreCard" element={ <ScoreCard /> } />
                <Route path="/countdown" element={ <Countdown /> } />
                <Route path="/outro" element={ <Outro /> } />
                <Route path="/exit-survey" element={ <ExitSurvey /> } />
                <Route path="/update-profile" element={ <PrivateRoute><UpdateProfile /></PrivateRoute> } />
                <Route path="/dash" element={ <PrivateRoute><Dash /></PrivateRoute> } />
                <Route path="/weekly-report" element={ <PrivateRoute><WeeklyActivity /></PrivateRoute> } />
                <Route path="/signup" element={ <Signup /> } />
                <Route path="/login" element={ <Login /> } />
                <Route path="/feedback" element={ <Feedback /> } />
                <Route path="/forgot-password" element={ <ForgotPassword /> } />
              </Routes>
            </AppProvider>            
          </AuthProvider>

        </Router> 

  );
}

export default App;
