import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { useApp } from './contexts/AppContext'
import { Link, useNavigate } from "react-router-dom"
import HundredSquare from "./HundredSquare";
import Tour from "./Tour"
import ScoreTour from "./ScoreTour"
import './app.css';
import PCcard from "./PCcard";
import Awards from "./Awards";
import { db } from "./firebase"
import { doc, setDoc, Timestamp } from "firebase/firestore"
import Logo from "./Logo";


export default function Colours2Numbers() {
  // console.log('Colours2Numbers render')

  const [numberOfCardNumChanges, setNumberOfCardNumChanges] = useState(0)    
  const [isColourBtnActive, setIsColourBtnActive] = useState(true)

  const navigate = useNavigate()


  const { 
    numberRange, 
    score, memoryEstimate, runningScore,
    setCardFlipped,
    disks2Display, setDisks2Display, setDisks,
    cardNum, setCardNum,
    freeDependencies,  
    COLOUR2MULTIPLE, 
    IsAspectRatioLandscape, 
    appSize, 
    userID,
    dateObject,
    levelOfPlay,
    getSecureFactors, setIsExpressionShown,
    dailyStreak, setDailyStreak, hourAsKeyDiff, dailyStreakUpdated, setDailyStreakUpdated, setHighestDailyStreak, highestDailyStreak, 
    numTimesFound, setNumTimesFound,
    failedAttempts, setFailedAttempts,
    firstFind, setFirstFind,
    earnedNumbers,setEarnedNumbers,
    foundNumbers, setFoundNumbers,      
    clickedNumbers, setClickedNumbers,
    areDataIdsReady,
    awards, checkAndSetAwards,
    helpModeOn,
    sessionDocId, dashDocId, scoreDocId, setDataInLogger,
    numOfHints, setNumOfHints, showHint,
    forgetCurve, 
    numberStability,
    NUMBER_STABILITY_BASE,
    getDateObject,
    setNumberOfFinds, setTimeOfLastNewFind,
    setNumberOfBtnClicks, setExitLink, dataLogger, updateDataLogger
  } = useApp() 
  const { currentUser, handleLogout, error } = useAuth() 
  let dashLog = {}
  
   useEffect(() => {
    setCardFlipped(false);
    if(helpModeOn) {
      setDisks(2,'red');
      // setDisks(5,'blue');
    }
    setExitLink({path: '/explore', text: 'Explore'})
    setIsExpressionShown(false)
   }, [])

    useEffect(() => {
      // if(currentUser !== 'guest'){
        if (!areDataIdsReady){
          navigate("/")
        }
        else {
        const timestamp = Timestamp.fromDate(new Date(Date.now()))

        const dataLabel = 'CardNum@(s)'+timestamp.seconds
        const dataLog = {}
        dataLog[dataLabel] = cardNum
        // console.log('docTrack',  sessionDocId)
        updateDataLogger(dataLog, ['sessionLog'], dataLogger);

        // setDoc(doc(db, sessionDocId), dataLog, {merge: true})  

        const dashKey = 'colourBtnClicks@'+userID
        dashLog[dashKey] = numberOfCardNumChanges 
        updateDataLogger(dashLog, ['dashLog'], dataLogger);

        // console.log('docTrack', )
        // setDoc(doc(db, dashDocId), dashLog, {merge: true})
  
        }
        // console.log('dataLog', dataLog)
      // }
    }, [cardNum])


    useEffect(() => {
      if (!areDataIdsReady){
        navigate("/")
      }
      else {

        let earnedScore = 0
        if(earnedNumbers.length > 0){
          earnedScore = earnedNumbers.reduce(function(prev, current) {
            return prev + current
          })
        }
          const foundScore = foundNumbers.length

    if (levelOfPlay > 2){
        const timestamp = Timestamp.fromDate(new Date(Date.now()))
        const dataLabel = 'levelOfPlayChange@(s)'+timestamp.seconds
        const dataLog = {}
        dataLog[dataLabel] = {
          foundScore, 
          earnedScore,
          levelOfPlay
        }
        // console.log('sessionDocId', sessionDocId)
        

        // console.log('docTrack', sessionDocId)
        updateDataLogger(dashLog, ['sessionLog'], dataLogger);

        // setDoc(doc(db, sessionDocId), dataLog, {merge: true})  
      if(currentUser !== 'guest'){        

        // console.log('docTrack',  'users/'+userID, dataLog)
        
        // setDoc(doc(db, 'users/'+userID), dataLog, {merge: true})
      }
    }
    }
      // console.log('dataLog', dataLog)
    }, [levelOfPlay, currentUser])


    // function notificationComplete() {
    //   setIsNotified(true)
    // }

    // function notification(){
    //   const msg = {
    //     heading: '',
    //     challenge: '',
    //     info: '',
    //     button: ''
    //   }
    //   msg.heading = currentUser ? 'You are now at '+LEVEL[levelOfPlay].title : 'You are now at '+LEVEL[levelOfPlay].title + "! As you are not logged in, this level won't be saved."
    //   msg.button = 'Continue Playing'
    //   msg.info = LEVEL[levelOfPlay].description
    //   return msg

    // }

    // console.log('helpModeOn', helpModeOn)

    return (
      <div className="App">

        

        <Container fluid> 
       
          <Row>
            <Col><Logo /></Col>
            

              <Col 
                style={{
                    padding: 'auto',
                    color: 'white',
                    fontSize: `${appSize/14}px`,
                    alignSelf: 'center'
                }}
              >Explore</Col>
              <Col
            sm={IsAspectRatioLandscape ? 4 : 10}></Col>
          </Row>

          <Row className="mt-5">
          <Col
                style={{
                    color: 'white',
                    fontSize: `${appSize/27}px`
                }}
            >
            {currentUser ? <>
            {(memoryEstimate > 1) ? <Link to='/scoreCard'><img src="images/statsButton.png" alt="button to stats page" width="80%" />Stats Page</Link> : <></>}
            {/* <div className="score-tour-mem">
              <p>Est. Num Cards in Memory</p>
              <p>{memoryEstimate}</p>              
            </div>
            <div className='score-tour-streak'>
            <p>Daily Streak</p>
            <p>{dailyStreak.streak}</p>
            <p>Highest Daily Streak</p>
            <p>{highestDailyStreak.streak}</p>               
            </div>
           <div className='score-tour-score'>
            <p>Score</p>
            <p>{score}</p>            
           </div> */}

            </> : <></>
            }

            </Col>
            <Col 
               style={{ 
                padding: "auto", 
                height: appSize}}
              sm={IsAspectRatioLandscape ? 5 : 10}
              >
              <HundredSquare  
                // className='tour-numBtns'
                cardNum = {cardNum}

                // clickedNumbers = {clickedNumbers}
                // foundNumbers = {foundNumbers}
                onNumberClicked = {(value) => {
                  // console.log('dailyStreakUpdated', dailyStreakUpdated);
                    setClickedNumbers(oldClickedNumbers => [...oldClickedNumbers, value])
                    setNumberOfBtnClicks(oldNumberOfButtonClicks => oldNumberOfButtonClicks + 1)
                    const timestamp = Timestamp.fromDate(new Date(Date.now()))
                    const dataLabel = 'NumberClicked@(s)'+timestamp.seconds
                    const data = {}
                    dashLog[dataLabel] =  {
                      cardNum, 
                      numberClicked: value
                    }
                    updateDataLogger(dashLog, ['sessionLog'], dataLogger);
          
                    // console.log('cardNum, value', dashLog[dataLabel])
                    // console.log('foundNumbers', foundNumbers.length)        

                    // console.log('docTrack', sessionDocId)
                    // setDoc(doc(db, sessionDocId), dataLog, {merge: true})       
                    const tempFailedAttempts = failedAttempts
                    tempFailedAttempts[cardNum] =  tempFailedAttempts[cardNum] + 1 

                    if (value===cardNum){
                      
                      const newEarnedNumbers = earnedNumbers
                      // console.log('stability', stability)
                      // How should the secure flag interact here? if secure, should it be possible to find
                      tempFailedAttempts[cardNum] =   tempFailedAttempts[cardNum] - 1
                      const tempNumTimesFound = numTimesFound
                      const secureFactors = getSecureFactors(cardNum);
                      for (let index = 0; index < secureFactors.length; index++) {
                        const element = secureFactors[index];
                        let stability = (numberStability[element]) ? (1+forgetCurve[element]*(1-forgetCurve[element]))*numberStability[element] : NUMBER_STABILITY_BASE;
                          // console.log('firstFind', firstFind);
                          // console.log('secureFactors[index]', secureFactors, index, secureFactors[index]);
                          if(firstFind[element]) {
                              const tempFirstFind = firstFind;
                              tempFirstFind[element] = false;
                              setFirstFind(tempFirstFind);
                              tempNumTimesFound[cardNum] = (!isNaN(tempNumTimesFound[cardNum])) ? tempNumTimesFound[cardNum] + 1 : 1;
                              setNumTimesFound(tempNumTimesFound)
                              stability = (tempFailedAttempts[element] === 0 && tempNumTimesFound[element] > 5 && element === cardNum) ? stability*1.3 : stability;
                              // console.log('stability', stability);
                              data[element] = {
                                  lastTimeFound: Timestamp.fromDate(new Date()),
                                  numTimesFound: (tempNumTimesFound[element]) ? tempNumTimesFound[element] : 1,
                                  stability: (stability > NUMBER_STABILITY_BASE) ? stability : NUMBER_STABILITY_BASE
                              }
                          }
                      }    
                      // console.log('data', data)
                      //quest! I need to think about how this should change and where the errors in score are coming from 
                      const isNewFind =  (newEarnedNumbers[value] === undefined ) 
                      // console.log({isNewFind}, {newEarnedNumbers}, {value})              
                      newEarnedNumbers[value] = 0
                      setNumberOfFinds(oldNumberOfFinds => oldNumberOfFinds + 1)
                      if (clickedNumbers.length > 5 && !awards['award_try_try_again']){
                      // console.log('checkAndSetAwards award_try_try_again');

                        checkAndSetAwards('award_try_try_again')
                      }
                      //set SurveyNumbers get one of each type for survey answers immediate, quick example
                      setEarnedNumbers(newEarnedNumbers)
                      setFoundNumbers(oldFoundNumbers => (oldFoundNumbers.includes(value) ? oldFoundNumbers : [...oldFoundNumbers, value]))
                      setCardFlipped(true)
                      setTimeout(function(){setCardFlipped(false)}, 1600)
                      // console.log('tempNumTimesFound', tempNumTimesFound)
                      if (isNewFind){
                        data['lastNewFindTime'] =  Timestamp.fromDate(new Date())
                        setTimeOfLastNewFind(data['lastNewFindTime'].seconds*1000)
                        setDoc(doc(db, 'users/'+userID), {lastNewFindTime: data['lastNewFindTime']}, {merge: true})
                        // console.log('docTrack',  'users/'+userID, {lastNewFindTime: data['lastNewFindTime']})
                        // console.log('runningScore', runningScore, score)
                        data['runningScore'] = runningScore + score;

                          const tempCurrentStreak =  {
                              streak: Math.floor(hourAsKeyDiff(dailyStreak.startDate, getDateObject().hourAsKey)/24 + 1), 
                              startDate: dailyStreak.startDate,
                              endDate: getDateObject().hourAsKey
                          }
                      // console.log('tempCurrentStreak', tempCurrentStreak)
                          const tempHighestStreak = (highestDailyStreak.streak > tempCurrentStreak.streak) ? highestDailyStreak : tempCurrentStreak
                          data['dailyStreak'] = {
                              currentStreak: tempCurrentStreak, 
                              highestStreak: tempHighestStreak
                          }
                          setDailyStreak(tempCurrentStreak)
                          setDailyStreakUpdated(true)
                          setHighestDailyStreak(tempHighestStreak)
                        setTimeout(function(){
                          setCardFlipped(false);
                          if (userID !== 'guest'){
                            const userDocId = 'users/'+ userID
                            setDataInLogger(userDocId, 'userLog');
                            setDataInLogger(scoreDocId, 'scoreLog');
                          }
                          setDataInLogger(sessionDocId, 'sessionLog');
                          setDataInLogger(dashDocId, 'dashLog');
                          // console.log({currentUser}, {userID});                        

                          if(currentUser.userName !== 'guest' && runningScore > 10 && (Math.random()*20 > 19)){
                            navigate("/exit-survey") 
                          }
                          else {
                            navigate("/outro")                         
                          }

                        }, 1600);
                        data['timeSpent'] =  data['lastNewFindTime'].seconds*1000 - dateObject.millisecs;
                        const dashKey = 'timespent@'+dateObject.millisecs
                          dashLog = {
                            [dashKey]: {[userID]: data['timeSpent']}
                          }                      
                      }
                      if( userID !== 'guest'){        
                      updateDataLogger(data, ['userLog'], dataLogger)
                        // console.log('docTrack',  'users/'+userID, data)
                        // setDoc(doc(db, 'users/'+userID), data, {merge: true})
                      }        
                      // setDoc(doc(db, dashDocId), dashLog, {merge: true})
                        updateDataLogger(dashLog, ['dashLog'], dataLogger)

                      updateDataLogger(data, ['sessionLog'], dataLogger)
                      // console.log('docTrack', sessionDocId)
                      // setDoc(doc(db, sessionDocId), data, {merge: true});
                    // console.log('stability: ', stability);

                    }
                    // console.log('forgetCurve: ', forgetCurve);
                    // console.log('NUMBER_STABILITY_BASE: ', NUMBER_STABILITY_BASE, 'numberStability[value]', numberStability[value], numberStability);
                      setFailedAttempts(tempFailedAttempts);
                }}
              />
              </Col>
              <Col>    
                 <Tour />
                {/*{ (numOfHints > 0 ) ? <img src='images/hint.png' onClick={() => showHint()} width='100%'/> : <></>} */}
              </Col>
              <Col sm={IsAspectRatioLandscape ? 4 : 10}>
              <PCcard 

               
                cardNum = {cardNum}
                isColourBtnActive = { isColourBtnActive }
                cardSize = {appSize}
                onDiskClicked = {(diskID) => {
                  const disk = disks2Display.find((diskInd2d) => diskInd2d.diskID === diskID )
                  const multiple = COLOUR2MULTIPLE[disk.colour]
                  // console.log('checkAndSetAwards award_colour_btns?');

                  checkAndSetAwards('award_colour_btns')
                  freeDependencies(disk.position)
                  setCardNum(prevCardNum => prevCardNum /multiple)
                  setClickedNumbers(oldClickedNumbers => [])
                  setNumberOfCardNumChanges(oldNumberOfCardNumChanges => oldNumberOfCardNumChanges + 1)

                  
                  setDisks2Display((oldDisks2Display) => oldDisks2Display.filter((diskInd2d) => diskInd2d.diskID !== diskID))

                }}
                onColourBtnClicked = {(multiple, colour) => {

                  // console.log({multiple, cardNum})
                  
                    if (multiple*cardNum > numberRange.high ||  multiple*cardNum < numberRange.low) return
                    setNumberOfCardNumChanges(oldNumberOfCardNumChanges => oldNumberOfCardNumChanges + 1)
                      setDisks(multiple, colour);

                    setClickedNumbers(oldClickedNumbers => [])
                  
                }}
                expression={null}

              />
              </Col>
              <Col></Col>
          </Row>
          {/* <Awards /> */}
          
        </Container>           
      </div>//for App

    );
}


