import React, { useContext, useState, useEffect } from 'react'
import appDimensions from '../hooks/useWindowDimensions'
import { db } from "../firebase"
import { doc, setDoc, Timestamp, getDoc } from "firebase/firestore"
import { useAuth } from './AuthContext'

const AppContext = React.createContext()

export function useApp() {
    return useContext(AppContext)
}

export function AppProvider({ children }) {

  const [numberRange, setNumberRange] = useState({
    low: 1, 
    high: 100
  })
  const [numberViewRange, setNumberViewRange] = useState({
    low: 1, 
    high: 100
  })
  const { currentUser } = useAuth() 
  const [isCardFlipped, setCardFlipped] = useState(false)
  const [disks2Display, setDisks2Display] = useState([])
  const [diskPositionDependencies, setDiskPositionDependencies] = useState(POSITION_ARRAY)
  const { IsAspectRatioLandscape, appSize, appWidth, appHeight } = appDimensions()    
  const [forgetCurve, setForgetCurve] = useState([])
  const [numberStability, setNumberStability] = useState([])
  const [earnedNumbers, setEarnedNumbers] = useState([])
  const [timeSinceLastFound, setTimeSinceLastFound] = useState([])
  const [clickedNumbers, setClickedNumbers] = useState([])
  const [foundNumbers, setFoundNumbers] = useState([])
  const [securedNumbers, setSecuredNumbers] = useState([])   
  const [introNumbers, setIntroNumbers] = useState([])   
  const [numTimesFound, setNumTimesFound] = useState([])      //sets number of times found in different sessions
  const [failedAttempts, setFailedAttempts] = useState([])  //tracks number of failed attempts at a particular cardNum
  const [firstFind, setFirstFind] = useState([])          // Flag for if this is first time found in session
  const [sessionDocId, setSessionDocId] = useState()
  const [dashDocId, setDashDocId] = useState()
  const [feedbackDocId, setFeedbackDocId] = useState()
  const [scoreDocId, setScoreDocId] = useState()
  const [levelOfPlay, setLevelOfPlay] = useState(2)  
  const [userID, setUserID] = useState('guest')
  const [score, setScore] = useState(0)
  const [maxScore, setMaxScore] = useState(0)
  const [memoryEstimate, setMemoryEstimate] = useState(0)
  const [hasStarted, setHasStarted] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [isNotified, setIsNotified] = useState(true)
  const [numberOfBtnClicks, setNumberOfBtnClicks] = useState(0)
  const [numberOfFinds, setNumberOfFinds] = useState(0)
  const [testNumbers, setTestNumbers] = useState([])
  const [factoriseExpressions, setFactoriseExpressions] = useState([])
  const [packArray, setPackArray] = useState([])
  const [packIsUnshuffled, setPackIsUnshuffled] = useState()
  const [surveyNumbers, setSurveyNumbers] = useState([])
  const [amtOfTestCards, setAmtOfTestCards] =  useState(0)
  const [isFlashTestStarted, setIsFlashTestStarted] = useState(false)
  const [isFactExpStarted, setIsFactExpStarted] = useState(false)
  const [amtOfFactoriseExp, setAmtOfFactoriseExp] =  useState(0)
  const [awards, setAwards] = useState({})
  const [dateObject, setDateObject] = useState(getDateObject())
  const [getData, setGetData] = useState(false)
  const [areDataIdsReady, setAreDataIdsReady] = useState(false)
  const [isLevelInitialised, setIsLevelInitialised] = useState(false)
  const [pcArray, setPcArray] = useState([])
  const [dailyStreakUpdated, setDailyStreakUpdated] = useState(false)
  const [dailyStreak, setDailyStreak] = useState({})
  const [highestDailyStreak, setHighestDailyStreak] = useState({})
  const [highScores, setHighScores] = useState([])
  const [userHighScore, setUserHighScore] = useState(0)
  const [helpModeOn, setHelpModeOn] = useState(false)
  const [cardNum, setCardNum] = useState(1)
  const [numOfHints, setNumOfHints] = useState(0)
  const [timeOfLastNewFind, setTimeOfLastNewFind] = useState(0)
  const [timeOfLastFlashTest, setTimeOfLastFlashTest] = useState(0)
  const [goToOutro, setGoToOutro] = useState(false)
  const [runningScore, setRunningScore] = useState(0)
  const [exitLink, setExitLink] = useState({})
  const [dataLogger, setDataLogger] = useState({dashLog: {}, sessionLog:{}, userLog: {}, feedbackLog: {}, scoreLog: {}})
  const [isExpressionShown, setIsExpressionShown] = useState(false)
  const [gameScore, setGameScore] = useState(0)

  
  function primeColoursfactorise(num){
    // console.log('number', num)
    const primeFactors = [2, 3, 5, 7, 11, 13, 17]
    let returnArray = [0,1]
    let testProduct = num
    for (var i = 0; i < primeFactors.length; i++) {
      let count = 0
      let product = num

      while (Number.isInteger(product/primeFactors[i])){
        product = product/primeFactors[i]
        testProduct = testProduct/primeFactors[i]
        // console.log('product', product)
        count++
      }
       returnArray.push(count) 
     } 
     if (testProduct === 1){
        returnArray.push(0)
      }
      else {
        returnArray.push(1)
      }
     return returnArray
  }

  useEffect(() => {
    // console.log('useEffect - tempPCArray', pcArray);

    const tempPCArray = [[1,0,0,0,0,0,0,0,0,0]]
    //quest! What's the point of firstFind? - flag for if first time found in session - Not sure how or if used
    const tempFirstFind = []
		for (let i = numberRange.low; i <= 400; i++) {
			tempPCArray.push(primeColoursfactorise(i))
      tempFirstFind[i] = true
		}
    // console.log('tempPCArray', tempPCArray)
    setPcArray(tempPCArray)
    setFirstFind(tempFirstFind)
  }, [numberRange])

  useEffect(() => {
    // console.log('useEffect - currentUser : Should happen before all data collection', currentUser.uid);

    const tempUserID = (currentUser.uid) ? currentUser.uid : 'guest';
    // console.log({tempUserID});
    setFoundNumbers([]);
    setClickedNumbers([]);
    setEarnedNumbers([]);
    setCardNum(1);
    setDisks2Display([]);
    setAwards({})
    setHasStarted(false);
    setGetData(true);
    setUserID(tempUserID);
  }, [currentUser]);

  useEffect(() => {
    // console.log({factoriseExpressions})
  }, [factoriseExpressions])


    function IsThisValidForLevel(num, level){
      const level_coloursLink = [2, 2, 2, 4, 2, 5, 3, 6, 8, 10 ]
      for (let index = level_coloursLink.length-1; index > 1 ; index--) {
        // console.log('pcArray[num][index]', pcArray[num][index], index, num)
        if (pcArray[num][index] > 0 && level_coloursLink[index] > level)
          return false
      }
      return true
    }

    useEffect(() => {
      // console.log({packArray, packIsUnshuffled})
      if (packArray.length === 0){
        setPackIsUnshuffled(true)
      }
    }, [packArray])


useEffect(() => {
  // console.log('useEffect - getData', getData, userID);

    if(getData){    
        
        // const dateAsKey = dateObject.dateAsKey
        // const monthAsKey = dateObject.monthAsKey
        const millisecs = dateObject.millisecs
        // console.log('millisecs set', millisecs)
      // console.log('dateObject', dateObject);
        setSessionDocId('users/'+ userID+'/sessions/'+millisecs)
        setDashDocId('dashdata/'+dateObject.hourAsKey)
        setFeedbackDocId('feedback/'+dateObject.hourAsKey)   
        setScoreDocId('dashdata/highScores') 
        const userIdArray = []
        let userSessions = {}
        // console.log('docTrack', 'dashdata/'+dateObject.hourAsKey)
        getDoc(doc(db, 'dashdata/'+dateObject.hourAsKey)).then((docSnap) => {
          if (docSnap.data()) {
            // console.log('currentDashdata', docSnap.data().userIds)
            if (docSnap.data().userIds){
              userIdArray.push(...docSnap.data().userIds)
            }
            
            // console.log('userIdArrayfromDb', userIdArray)
            userSessions = docSnap.data().sessions
          }
          const sessions = {
            [millisecs] : userID,
            ...userSessions
          }
          // console.log('sessions', sessions)
          // console.log('userIdArraycurrent', userIdArray)
          if (!userIdArray.includes(userID)){
            userIdArray.push(userID)            
          }

          const dataLog = {}
          dataLog['Session@'] = dateObject.hourAsKey

  
// MillisecStarted is used for the query when collecting all the dash data
          const dashLog = {
            MillisecStarted: millisecs,
            userIds: userIdArray,
            sessions: sessions
          }
          if (userID !== 'guest'){
            // console.log('docTrack', 'users/'+ userID+'/sessions/'+millisecs)
            updateDataLogger(dataLog, ['sessionLog'], dataLogger);
            // setDoc(doc(db, 'users/'+ userID+'/sessions/'+millisecs), dataLog, {merge: true})
         }
          // console.log('docTrack', userID+'/sessions/'+millisecs)
          updateDataLogger(dashLog, ['dashLog'], dataLogger);
          
          // setDoc(doc(db, 'dashdata/'+dateObject.hourAsKey), dashLog, {merge: true})
        })   

      setAreDataIdsReady(true)
    }

  },[getData, userID, currentUser])  

  function mergeObject(key, data, existingData){
    // Needs to update existingData object not just return the object.  how do I merge the parts of the object that are not part of this path?
    const label = key.shift()
    // console.log('mO_label', label, key)

    if (existingData[label]){
      // console.log('There is this key, ',label, 'in existingData Object. ',key)
      const returnObject = (key.length === 0) ? {[label]: data} : {[label]: mergeObject(key, data, existingData[label])}
      existingData[label] = {...existingData[label], ...returnObject[label]}
      // console.log('return Object with Label - ', returnObject, existingData)
      return {...existingData};
    }
    // console.log('This Key, ', label, 'is not in merging object.  Key', key)
    const returnBuild = (key.length === 0) ? {[label]: data} : {[label]: buildObject(key, data)}
    // console.log('return Object without Label - ', returnBuild)
    return returnBuild
    
      
  
  }

  function buildObject(key, data){
    const label = key.shift()
    // console.log('bO_label', label, key)
    if (key.length === 0){
      return {[label]: data} 
    }
    const returnObject = {[label]: buildObject(key, data)}
    return returnObject;

  }

  function updateDataLogger(newData, key, tempDataLogger){
    // console.log('dataLogger ', newData, key, tempDataLogger)
    //key can be array trail of keys to the object.  Use a recursive function to create object
    const tempData = mergeObject(key, newData, tempDataLogger)

    // console.log('tempData', tempData)

    setDataLogger(tempData)
  }

  useEffect(() => {
    // console.log('useEffect - userID', userID);

    if (userID !== 'guest'){
      const initialHighScore = {
        [userID]: {
        score: 0,
        date: dateObject.hourAsKey
        }
      }           
      getDoc(doc(db, 'dashdata/highScores')).then((docSnap2) => {
        if (docSnap2.data()) {
          // console.log('SetHighScore - docSnap2.data()');
          updateDataLogger(docSnap2.data(), ['scoreLog'], dataLogger)
          // console.log('docSnap.data()', docSnap2.data());
          // if (docSnap2.data()[userID]) {
          //   const tempScore = (docSnap2.data()[userID].score) ? docSnap2.data()[userID].score : 0;
          //   setUserHighScore(tempScore)
          // } 
        }
        // else{
        //   // console.log('SetHighScore - initialHighScore');
        //   setHighScores(initialHighScore);
        // }
      })
    }
  }, [userID, currentUser]);

  // function getUserHighScores(highScoresObj) {
  //   // console.log('highScoresObj', highScoresObj);
  //   let tempHighScores = [];
  //   Object.keys(highScoresObj).forEach(uid => {
  //     if(uid !== 'highScores'){
  //       const tempScore = (highScoresObj[uid].score) ? highScoresObj[uid].score : highScoresObj[uid];
  //       tempHighScores = updateHighScores(tempScore, uid, tempHighScores);
  //   // console.log('tempHighScores', uid, tempHighScores);
        
  //     }
  //   });
  //   return tempHighScores;
  // }

// //testData in Node: console.log(updateHighScores(6, 234)); console.log(updateHighScores(4, 123)) console.log(updateHighScores(11, 345))
// // const highScores = [{ score: 3, userID: 234 },  { score: 5, userID: 345 },  { score: 7, userID: 654 }]
// function updateHighScores(scr, uid, tempHighScores){
//   const currentUserIndex = tempHighScores.findIndex(score => score.userID === uid)
//     const newHighScores = (currentUserIndex === -1 ? [...tempHighScores, {score: scr,userID: uid}] : [...tempHighScores.slice(0, currentUserIndex),{score: scr, userID: uid}, ...tempHighScores.slice(currentUserIndex + 1)]).sort((a, b) => b.score - a.score)
//       return newHighScores
// }

function viewHighScores(){

}


  useEffect(() => {
    // console.log('useEffect - score, userHigh, highScores', score, userHighScore, highScores);
    // console.log('score change', score)
    //quest! causes uids to be visible.  Probably not a good idea.  How can I get around this?
    if (userID !== 'guest'){
      // console.log('highScores', highScores, userHighScore)
      if (dataLogger['scoreLog'][userID] === undefined || score > dataLogger['scoreLog'][userID]['score']){
        // setUserHighScore(score)
        // const tempHighScores = updateHighScores(score, userID, highScores)
        // // console.log('tempHighScores', tempHighScores)
        // setHighScores(tempHighScores)
        updateDataLogger({score, date: dateObject.hourAsKey}, ['scoreLog',userID], dataLogger)
        // console.log('new high score ',dataLogger['scoreLog'][userID], userID)
        // console.log('dashLog', dashLog)
        
        // setDoc(doc(db, 'dashdata/highScores'), dashLog, {merge: true})
      }
    }
  }, [score, userHighScore, highScores, userID, currentUser])

  
 // TESTDATA console.log(hourAsKeyDiff(2022062921, 2022070103), 'expect 30'); console.log(hourAsKeyDiff(2022123119, 2023010107), 'expect 12'); console.log(hourAsKeyDiff(2022122910, 2023010116), 'expect 78'); console.log(hourAsKeyDiff(2022070119, 2022070207), 'expect 12'); 
  function hourAsKeyDiff(start, end){
    const monthDays = [31,28,31,30,31,30,31,31,30,31,30,31];
    if (Number.isInteger(Math.floor(end/1000000)/4)) {
      monthDays[1] = 29;
    }
    const start_Y_M_D_H = {
      year: Math.floor(start/1000000),
      month: (Math.floor(start/10000)*10000-Math.floor(start/1000000)*1000000)/10000,
      date: (Math.floor(start/100)*100-Math.floor(start/10000)*10000)/100,
      hour: start-Math.floor(start/100)*100};
    const end_Y_M_D_H = {
      year: Math.floor(end/1000000),
      month: (Math.floor(end/10000)*10000-Math.floor(end/1000000)*1000000)/10000,
      date: (Math.floor(end/100)*100-Math.floor(end/10000)*10000)/100,
      hour: end-Math.floor(end/100)*100};
      // console.log(start_Y_M_D_H, end_Y_M_D_H);
      const yearDiff = end_Y_M_D_H.year - start_Y_M_D_H.year; 
      const monthDiff = end_Y_M_D_H.month + (yearDiff * 12) - start_Y_M_D_H.month;
      const dateDiff = end_Y_M_D_H.date + (monthDiff * monthDays[start_Y_M_D_H.month -1]) - start_Y_M_D_H.date;
      const hourDiff = end_Y_M_D_H.hour + (dateDiff * 24) - start_Y_M_D_H.hour;
      // console.log('', monthDays[start_Y_M_D_H.month -1], monthDays,start_Y_M_D_H.month )
      // console.log(yearDiff, monthDiff, dateDiff, hourDiff)
      return hourDiff;
  }

  function isNewStreak(now, start, end, streak){
    // console.log('isNewStreakCalcs', hourAsKeyDiff(end, now),'start to end', Math.floor((now - start)/100), 'streak', streak)
    // console.log('isNewStreakbools', hourAsKeyDiff(end, now) > 12, streak - (Math.floor((now - start)/100))
    if((streak - hourAsKeyDiff(start, now)/24) < 2){
      
      return true
    } 
    
    return false
  }

  function setBaseStreaks(){
    const baseStreak = {
      streak: 0, 
      startDate: dateObject.hourAsKey,
      endDate: dateObject.hourAsKey
    }

    setDailyStreak(baseStreak)
    setHighestDailyStreak(baseStreak)
    // console.log('setBaseStreaks', baseStreak);

  }

  function isCurrentStreakValid (endDate){
    // console.log('is current streak valid',endDate,dateObject.hourAsKey)
      return (hourAsKeyDiff(endDate,dateObject.hourAsKey) < 48)
  }

  function quarterIncluding(cardNum){
    const quadrant1 = [1,2,3,4,5,11,12,13,14,15,21,22,23,24,25,31,32,33,34,35,41,42,43,44,45];
    const quadrant2 = [51,52,53,54,55,61,62,63,64,65,71,72,73,74,75,81,82,83,84,85,91,92,93,94,95];
    const quadrant3 = [6, 7, 8, 9, 10, 16, 17, 18, 19, 20, 26, 27, 28, 29, 30, 36, 37, 38, 39, 40, 46, 47, 48, 49, 50];
    const quadrant4 = [56, 57, 58, 59, 60, 66, 67, 68, 69,70, 76, 77, 78, 79, 80, 86, 87, 88, 89, 90, 96, 97, 98, 99, 100];
    const lowerHalf = (cardNum < 51);
    const leftHalf = ((cardNum-1) % 10 < 5);
    return lowerHalf ? leftHalf ? quadrant1 : quadrant3 : leftHalf ? quadrant2 : quadrant4
  }

  function showHint(){

    setNumOfHints(oldNumOfHints => oldNumOfHints - 1);
    // console.log('quarterIncluding',quarterIncluding(cardNum)); // get show hint to mark the numbers in someway and if a guess happens, the mark disappears.  e.g. if quad1 is marked and 4 is guessed wrongly, the mark is removed from 4 and the rest stays
    // console.log('numOfHints', numOfHints);
  }
  
  function dateTimeFromUnixMillisecs(dateInMillisecs){
    return new Date(dateInMillisecs);
  }

  function timeDiffInHoursMinsSecs(start, end){
    const diffInMillisecs = end - start;
    return convertMillisecsToHMS(diffInMillisecs);
  }

  function convertMillisecsToHMS(diffInMilSecs){
    const hoursLeft = Math.floor(diffInMilSecs/60/60/1000);
    const minutesLeft = Math.floor((diffInMilSecs - hoursLeft*60*60*1000)/60000);
    const secondsLeft = Math.floor((diffInMilSecs - hoursLeft*60*60*1000 - minutesLeft*60000)/1000);
    return {
      hours: hoursLeft, 
      minutes: minutesLeft,
      seconds: secondsLeft
    }  
  }

  function timeUntilNextSession(lastFindMilli) {
    const timeNow = Date.now();
    // const nowInMillisecs = new Date()
    // console.log('timeToNextSession', timeNextSession)
    const timeNextSession = lastFindMilli + 12*60*60*1000
    const millisecondsToNextSession = timeNextSession - timeNow; 
    // console.log('timeOfLastNewFind', timeOfLastNewFind)
    // console.log('secondsToNextSession', secondsToNextSession)
    if (millisecondsToNextSession < 0){
      return 0;
    }
    else {
      const hoursLeft = Math.floor(millisecondsToNextSession/3600000);
      const minutesLeft = Math.floor((millisecondsToNextSession - hoursLeft*60*60*1000)/60000)
      const secondsLeft = Math.floor((millisecondsToNextSession - hoursLeft*3600000 - minutesLeft*60000)/1000)
      const nextSeshString = timeNextSession.toLocaleString()
      const timeToNextSession = {
        hours: hoursLeft, 
        minutes: minutesLeft,
        seconds: secondsLeft,
        nextSession: nextSeshString
      }
      // console.log('timeToNextSession', timeToNextSession)
      return timeToNextSession;
    }
  }

  function formatSingleDigitMinsSecs(num){
    return (num > 9) ? num : '0'+num;
  }

  function shuffleArray(array) {
    let curId = array.length;
    // There remain elements to shuffle
    while (0 !== curId) {
      // Pick a remaining element
      let randId = Math.floor(Math.random() * curId);
      curId -= 1;
      // Swap it with the current element.
      let tmp = array[curId];
      array[curId] = array[randId];
      array[randId] = tmp;
    }
    return array;
  }

  function addNumstoMakeMultipleOfFactor(factor, arr){
    const sum = arr.reduce((a, b) => parseInt(a) + parseInt(b), 0)
    let extraCardsSum = 2*factor - sum%factor
    while (extraCardsSum > 0){
      const newCardSelection =  arr.filter(function(number) {
        return number <= extraCardsSum
      })
      //assumes there is a one in the pack and won't run this until there 1,2,4 and 5 are there.
      const newCard = newCardSelection[Math.floor(Math.random() * newCardSelection.length)]
      extraCardsSum = extraCardsSum - newCard
      // console.log({sum, extraCardsSum})
      arr.push(newCard)

    }
    return arr

  }

  

  useEffect(() => {
    // console.log({packIsUnshuffled});
    if(userID !== 'guest'){    
      // console.log('earnedNumbers', earnedNumbers)
      // console.log('docTrack', 'users/' + userID)

      const earnedNumRef = doc(db, 'users/' + userID )
      getDoc(earnedNumRef).then((docSnap) => {
        
        if (docSnap.data()) {
          const earnedNumbersResult = []
          const stability = []
          const fCurve = []
          const tempPackArray = []
          const baseStreak = {
            streak: 0, 
            startDate: dateObject.hourAsKey,
            endDate: dateObject.hourAsKey
          }
          // console.log({baseStreak});

          if(docSnap.data().runningScore){
            setRunningScore(docSnap.data().runningScore)
          }

          // console.log('docSnap',docSnap.data()['lastNewFindTime'])
          if(docSnap.data().lastNewFindTime){
            
            setTimeOfLastNewFind(docSnap.data()['lastNewFindTime']['seconds']*1000)
            // console.log('setTimeOfLastNewFind', docSnap.data()['lastNewFindTime']['seconds']*1000)
          }

          if(docSnap.data().lastFlashTest){
            setTimeOfLastFlashTest(docSnap.data().lastFlashTest)
          }

          // if(docSnap.data().numOfHints){
          //   setNumOfHints(docSnap.data().numOfHints);
          // }
          // else {
          //   setNumOfHints(5);
          //   setDoc(doc(db, 'users/'+ userID), {numOfHints: 5}, {merge: true});
          // }
      
          let dailyStreakStability = 0
           if(docSnap.data().dailyStreak){
            const highestStreak = docSnap.data().dailyStreak.highestStreak
            const currentRecordedStreak = (isCurrentStreakValid(docSnap.data().dailyStreak.currentStreak.endDate)) ? docSnap.data().dailyStreak.currentStreak : baseStreak
            // console.log('currentstreak doc', docSnap.data().dailyStreak.currentStreak, {currentRecordedStreak}, (isCurrentStreakValid(docSnap.data().dailyStreak.currentStreak.endDate)))
            // console.log('currentStreak', docSnap.data().dailyStreak.currentStreak)
            // console.log('isNewStreakTest', isNewStreak( getDateObject().hourAsKey, docSnap.data().dailyStreak.currentStreak.startDate, docSnap.data().dailyStreak.currentStreak.endDate, docSnap.data().dailyStreak.currentStreak.streak))
            setDailyStreak(currentRecordedStreak)
            // setDailyStreakUpdated(!isNewStreak( dateObject.hourAsKey, docSnap.data().dailyStreak.currentStreak.startDate, docSnap.data().dailyStreak.currentStreak.endDate, docSnap.data().dailyStreak.currentStreak.streak))
            setHighestDailyStreak(highestStreak)
            dailyStreakStability = currentRecordedStreak * 0.01 + 1

          }
          else{
              setBaseStreaks()
          }

 

          let count = 0
          const tempTimeSinceLastFound = []
          const tempNumTimesFound = []
          const tempSecuredNumbers = []
          const tempFirstFind = firstFind
          // console.log('docSnap.data().awards', docSnap.data().awards)
          let tempAwards = {};
          const temp = (docSnap.data().awards) ? docSnap.data().awards : awards 
          if(Array.isArray(temp)){
            // console.log('temp', temp);
            for (let index = 0; index < temp.length; index++)  {
              if (temp[index]){
                tempAwards[awardInfoArray[index].tip] = true;
              }
              // console.log('tempAwards', tempAwards)
            }
          } 
          else {
            tempAwards = temp;
          }
          updateDataLogger(tempAwards, ['userLog', 'awards'], dataLogger);

          Object.keys(docSnap.data()).forEach(element => {
            if (!isNaN(element)) {
              const timeNow = Timestamp.fromDate(new Date())['seconds']
              tempNumTimesFound[element] = (docSnap.data()[element]['numTimesFound']) ? docSnap.data()[element]['numTimesFound'] : 1
              const timeLastFound = docSnap.data()[element]['lastTimeFound']['seconds']
              stability[element] = docSnap.data()[element]['stability'] ? docSnap.data()[element]['stability'] : NUMBER_STABILITY_BASE * dailyStreakStability
   //**********************************  Stability Implementation  ***************************************************************/
              const timeSinceLastFind = timeNow - timeLastFound
              tempTimeSinceLastFound[element] = timeSinceLastFind
              // sets whether data on percent number of finds and whether this is a secure number as only the first find in hour period contributes to this
              // console.log('timeSinceLastFind', timeSinceLastFind);
              tempFirstFind[element] = (timeSinceLastFind > 7*60*60) ? true : false  
              fCurve[element] = Math.log((timeSinceLastFind)/stability[element] + 1);
              fCurve[element] = (fCurve[element] < 1) ? fCurve[element] : 1;
              // console.log('timeDiff',(timeNow - timeLastFound)/stability[element], Math.log((timeNow - timeLastFound)/stability[element] + 1), 'fCurve[element]', fCurve[element])
              earnedNumbersResult[element]  = 1 - fCurve[element];
              if (element <= 100){
                tempPackArray.push(element) 
              }
              //quest! change tempNumTimesFound[element] > 3 to tempNumTimesFound[element] > 9
              if (tempNumTimesFound[element] > 4 && stability[element]/NUMBER_STABILITY_BASE > 3 && earnedNumbersResult[element] > 0){
                tempSecuredNumbers.push(parseInt(element))
                earnedNumbersResult[element] = 1
                tempAwards['award_number_lock'] = true;
                checkAndSetAwards('award_number_lock')
              }
              count = count+1
            }
          })
          //Consider learning to use useReducer for this instead of useState
          // console.log({packIsUnshuffled, tempPackArray})
          if (packIsUnshuffled && tempPackArray.length > 0){
            // console.log({packIsUnshuffled, tempPackArray})
            const additionalPack = addNumstoMakeMultipleOfFactor(100, tempPackArray)
            setPackIsUnshuffled(false)
            setPackArray(shuffleArray(additionalPack))            
          }


          if(count > 6 && count <= 22){
            const numFlashCards = Math.floor(Math.sqrt(count))+1
            // console.log('numFlashCards', numFlashCards);
            setAmtOfTestCards(numFlashCards)
          }
          // console.log({count})
          if(count > 22){
            const numFactoriseExp = Math.floor(Math.sqrt(count/3))+1
            const numFlashCards = Math.floor(Math.sqrt(count)/2)+1
            // console.log('numFlashCards', numFlashCards);
            setAmtOfTestCards(numFlashCards)
            setAmtOfFactoriseExp(numFactoriseExp)
          }
           

          // console.log('earnedNumbersResult', earnedNumbersResult)
    // ******************************************************* Get awards data *********************************
          setEarnedNumbers(earnedNumbersResult);
          // console.log('earnedNumbersResult', earnedNumbersResult);
          // console.log('stability', stability);
          setNumberStability(stability)
          setForgetCurve(fCurve)                
          setSecuredNumbers(oldSecuredNumbers => {
            tempSecuredNumbers.forEach(element => {
              if(!oldSecuredNumbers.includes(element))
                oldSecuredNumbers.push(element)
            });
            return oldSecuredNumbers
          })
          // console.log('docSnap.data().awards', docSnap.data().awards, 'tempAwards', tempAwards)
          setAwards(tempAwards);
          // console.log('tempFirstFind', tempFirstFind);
          setFirstFind(tempFirstFind);
          setNumTimesFound(tempNumTimesFound);
          setTimeSinceLastFound(tempTimeSinceLastFound)
        } 
        else {
          setBaseStreaks();
        }


      })      
    }
    else {
      setBaseStreaks();
    }
  }, [areDataIdsReady, userID, currentUser, packIsUnshuffled])

  function arrayMax(arr) {
    let len = arr.length;
    let max = -Infinity;
    let maxIndex = 0
    while (len--) {
      if (arr[len] > max) {
        max = arr[len];
        maxIndex = len
      }
    }
    return maxIndex;
  }


  function arrayMin(arr) {
    let len = arr.length;
    let min = Infinity;
    let minIndex = 0
    while (len--) {
      // console.log('arr[len]', arr[len], len)
      if (arr[len] < min) {
        min = arr[len];
        minIndex = len
      }
    }
    return minIndex;
  }

  useEffect(() => {
    // console.log('useEffect - amtOfTestCards', amtOfTestCards);
    // console.log('set Test Numbers')
    if(userID !== 'guest' && isLevelInitialised){
      const tempTestNums = []
      const tempTestNumsIndex = []
      const tempTestNumsUpper = []
      const tempTestNumsUpperIndex = []
      const tempFactExp = []
      const testNums = []
      const surveyNums = []
      const spareTestNums = []
      const tempTimeSinceLastFound = timeSinceLastFound
      // console.log('earnedNumbers', earnedNumbers)
      // console.log('levelofPlay', levelOfPlay)
      const maxNumber = (earnedNumbers.length > numberRange.high) ? numberRange.high : earnedNumbers.length

      for (let index = 1; index <= maxNumber; index++) {
        if(IsThisValidForLevel(index, levelOfPlay)){
          // console.log('IsThisValidForLevel', index, IsThisValidForLevel(index, levelOfPlay))
          //quest! Why prioritise earned amounts under 0.3 rather than 0.5?
          // console.log('earnedNumbers[index]', earnedNumbers[index], index)
          if(earnedNumbers[index] !== undefined){
            if(earnedNumbers[index] < 0.3){
              
              tempTestNums.push(earnedNumbers[index])
              tempTestNumsIndex.push(index)
            }
            else{

              tempTestNumsUpper.push(earnedNumbers[index])
              tempTestNumsUpperIndex.push(index)
            } 
          }
          else {
            // console.log({tempFactExp})
            tempFactExp.push(index)
            // console.log({index,tempFactExp})
          }

            // console.log('testNum Loop', index, tempTestNums, tempTestNumsIndex, amtOfTestCards)
        }
      }
      // console.log({tempFactExp, amtOfFactoriseExp})

      while (tempFactExp.length > amtOfFactoriseExp){
        tempFactExp.splice(Math.floor(Math.random()*tempFactExp.length),1)
        // console.log({tempFactExp, amtOfFactoriseExp})
      }
      // console.log({tempFactExp})
      
      // console.log('tempTestNums.length', tempTestNums.length, amtOfTestCards)
      if (tempTestNums.length > amtOfTestCards){
        // console.log('tempTestNums', tempTestNums)        
        // console.log('tempTestNumsIndex', tempTestNumsIndex) 
        for (let i = 0; i < amtOfTestCards; i++) {
          const currentMaxIndex = arrayMax(tempTestNums)
          testNums.push(tempTestNumsIndex[currentMaxIndex])
          tempTestNums.splice(currentMaxIndex, 1)
          tempTestNumsIndex.splice(currentMaxIndex, 1)          
        }
        spareTestNums.push(...tempTestNumsIndex)
        surveyNums.push(...tempTestNumsIndex)
        // console.log('surveyNums', surveyNums)
        while (surveyNums.length > 2) {
          surveyNums.splice(Math.floor(Math.random()*surveyNums.length),1)


        }
        // console.log('surveyNums', surveyNums)
        setSurveyNumbers(surveyNums)
      }
      else{
        // console.log('tempTestNumsUpper', tempTestNumsUpper)        
        // console.log('tempTestNumsUpperIndex', tempTestNumsUpperIndex)        
        testNums.push(...tempTestNumsIndex)
        for (let index = tempTestNums.length; index < amtOfTestCards; index++) {
          //quest! What's wrong with this?
          const currentMinIndex = arrayMin(tempTestNumsUpper)
          // console.log('tempTestNumsUpperIndex[currentMinIndex]', tempTestNumsUpperIndex[currentMinIndex], currentMinIndex)
          testNums.push(tempTestNumsUpperIndex[currentMinIndex])
          tempTestNumsUpper.splice(currentMinIndex, 1)
          tempTestNumsUpperIndex.splice(currentMinIndex, 1)
          
        }
        spareTestNums.push(...tempTestNumsUpperIndex)
        surveyNums.push(...tempTestNumsUpperIndex)
        // console.log('surveyNums', surveyNums)
        while (surveyNums.length > 2) {
          surveyNums.splice(Math.floor(Math.random()*surveyNums.length),1)

        }
        // console.log('surveyNums', surveyNums)
        setSurveyNumbers(surveyNums)

      }
      // console.log('testNums', testNums)

      while (tempFactExp.length < amtOfFactoriseExp && spareTestNums.length > 0) {
        tempFactExp.push(...spareTestNums.splice(Math.floor(Math.random()*spareTestNums.length),1))
        // console.log({tempFactExp})
      }

      let tempIndex = arrayMax(tempTimeSinceLastFound)
      if (tempIndex > 0){
        tempFactExp.push(tempIndex)
      }
      
      // console.log({tempIndex,tempFactExp})
      tempTimeSinceLastFound.splice(tempIndex, 1)
    if (!isFactExpStarted && !isFlashTestStarted){
      // console.log({tempFactExp})
      setFactoriseExpressions(tempFactExp)  
    }
    tempIndex = arrayMax(tempTimeSinceLastFound)
    testNums.push(tempIndex)

     if (!isFlashTestStarted){
    // console.log('setTestNums')    
      setTestNumbers(testNums)
      }
      // setSurveyNumbers(surveyNums)
    }
  }, [ amtOfTestCards,amtOfFactoriseExp, earnedNumbers, timeSinceLastFound, isLevelInitialised, userID, pcArray, awards, currentUser ])

  
 //testData in Node: console.log(countMultiplesOf(2), countMultiplesOf(3),countMultiplesOf(4), countMultiplesOf(5),countMultiplesOf(6), countMultiplesOf(7))
 // const earnedNumbers = [0,1,1,1,1,1,1,1,1,0,1,1,1,1,1,0,1,1,1,1,1,0,1,0,1,1,1,1,0,1,1,1,1];  const foundNumbers = [45, 60, 72, 123, 150]
  function countMultiplesOf(base){
    let amtOfMultiplesOf = 0
    for (let i = 1; i < 400/base; i++) {
      if (earnedNumbers[i*base] > 0.3){ amtOfMultiplesOf++  }
      else if (foundNumbers.includes(i*base)){ amtOfMultiplesOf++ }
    } 
    return amtOfMultiplesOf 
  }
  
  useEffect(() => {
    // console.log('useEffect - earnedNumbers, foundNumbers, securedNumbers, daily sreak', earnedNumbers, foundNumbers, securedNumbers, dailyStreak);
   
      setLevelOfPlay(2)
      if (!awards[4]){
        if (countMultiplesOf(10) >= 10 ){    
          // console.log('checkAndSetAwards award_ten_multiples_10');
 
          checkAndSetAwards('award_ten_multiples_10')
        }
      }
      if(earnedNumbers.length > 0){
        const earnedScore = earnedNumbers.reduce(function(prev, current) {
          return prev + current
        })
        const prevFoundCards = earnedNumbers.filter(num => !isNaN(num)).length       
        // console.log('previousCards', prevFoundCards, earnedNumbers) 
        const foundScore = foundNumbers.length
        // console.log('foundNumbers', foundNumbers)
        const introScore = introNumbers.length
        const securedScore = securedNumbers.length * 0.1
        // console.log('securedScore', securedScore)
        // console.log('securedNumbers', securedNumbers)
        // console.log('score, fscore, secscore dailystreak + calc', score, foundScore, securedScore, dailyStreak.streak, Math.round((foundScore + securedScore)* ((dailyStreak.streak >= 1) ? dailyStreak.streak : 1)), )
        setScore(Math.round((foundScore + securedScore + introScore + gameScore)* Math.sqrt((dailyStreak.streak >= 1) ? dailyStreak.streak : 1)))

        setMaxScore(Math.round((prevFoundCards + securedScore+ introScore)* Math.sqrt((dailyStreak.streak >= 1) ? dailyStreak.streak : 1)))

        setMemoryEstimate(Math.round((foundScore + earnedScore)*10)/10)
        if ((foundScore + earnedScore) > 10 && (foundScore + earnedScore) < 16){
          setLevelOfPlay(3)
        }
        if ((foundScore + earnedScore) >= 16 && (foundScore + earnedScore) < 36){
          setLevelOfPlay(4)
        }
        if ((foundScore + earnedScore) >= 36 && (foundScore + earnedScore) < 49){
          setLevelOfPlay(5)
        }
        if ((foundScore + earnedScore) >= 49 && (foundScore + earnedScore) < 56){
          setLevelOfPlay(6)
        }
        if ((foundScore + earnedScore) >= 56 && (foundScore + earnedScore) < 80){
          setLevelOfPlay(7)
          setNumberRange({
            low: 1, 
            high: 200
          })
          // console.log('checkAndSetAwards award_number_range_increase');
          checkAndSetAwards('award_number_range_increase')            
        }
          if ((foundScore + earnedScore) >= 80){
            setLevelOfPlay(8)
            setNumberRange({
              low: 1, 
              high: 300
            })
          
    
        }
      }
      setIsLevelInitialised(true)
  
  }, [ earnedNumbers, foundNumbers, securedNumbers, gameScore, introNumbers, dailyStreak, currentUser])

  function checkAndSetAwards(awardName){
    // console.log('awards', awards);
    //Awards [0] = colourBtns, [1] = guessing lots of times and finally succeeding, [2] = flash card correct, [3] = finding a number greater than 100, [4] = finding 10 multiples of 10, [5] = securing numbers
    if (!awards[awardName]){
      const awardArray = awards
      awardArray[awardName] = true
      // console.log('awardArray', awardArray);
      setAwards(awardArray)
      const awardData = {};
      awardData['awards'] = awardArray;
      updateDataLogger(awardArray, ['userLog', 'awards'], dataLogger);
      // console.log('docTrack', 'users/'+userID, awardData)
      // setDoc(doc(db, 'users/'+userID), awardData, {merge: true})
    }

  }
  
  
  useEffect(() => {
    // console.log('useEffect - clickedNumbers', clickedNumbers);

    if(areDataIdsReady){
      // console.log('numberOfBtnClicks', numberOfBtnClicks)
      // console.log('numberOfFinds', numberOfFinds)
      const dashLog = {}
      const dashKey = 'btnClicks@'+userID
      dashLog[dashKey] = numberOfBtnClicks 
      const dashKeyFinds = 'finds@'+userID
      dashLog[dashKeyFinds] = numberOfFinds 
      // console.log('docTrack', dashDocId)
      // console.log('dashLog', dashLog)
      updateDataLogger(dashLog, ['dashLog'], dataLogger)
      // setDoc(doc(db, dashDocId), dashLog, {merge: true}) 
    }
  }, [clickedNumbers, numberOfBtnClicks, numberOfFinds, currentUser])

    function getDateObject(){
      const returnObj = {}
      const todaysDate = new Date(Date.now())
      const monthNumber = todaysDate.getMonth()+1
      const dateNumber = todaysDate.getDate()
      const hourNumber = todaysDate.getHours()
      const monthDigits = (monthNumber > 9) ? monthNumber : '0'+monthNumber
      const dateDigits = (dateNumber > 9) ? dateNumber : '0'+dateNumber
      const hourDigits = (hourNumber > 9) ? hourNumber : '0'+hourNumber
      const hourAsString = todaysDate.getFullYear()+' '+monthDigits+' '+dateDigits+' '+hourDigits
      const dateAsString = todaysDate.getFullYear()+' '+monthDigits+' '+dateDigits
      const monthAsString = todaysDate.getFullYear()+' '+monthDigits
      returnObj['hourAsKey'] = hourAsString.replace(/\s+/g, '')
      returnObj['dateAsKey'] = dateAsString.replace(/\s+/g, '')
      returnObj['monthAsKey'] = monthAsString.replace(/\s+/g, '')
      const timestamp = Timestamp.fromDate(todaysDate)
      returnObj['millisecs'] = Math.round((timestamp.seconds*1000000000 + timestamp.nanoseconds)/1000000);
      // console.log('millisecs returned',returnObj['millisecs']);
      return returnObj
    }

  function onViewRangeChange(change) {
    const newViewRange = numberViewRange
    if (newViewRange.low + change >= numberRange.low && newViewRange.high + change <= numberRange.high) {
      newViewRange.low = newViewRange.low + change
      newViewRange.high = newViewRange.high + change
      setNumberViewRange(newViewRange)
      // console.log('numberViewRange', numberViewRange) 
      
    }

  }



  //TESTED CORRECT in currentlyFreePositions
  function getNeighbouringPositions(position){
    const positionArray = [position]
    if (position < GRID_SIZE*(GRID_SIZE-1)){positionArray.push(position + GRID_SIZE)}
    if (position < GRID_SIZE*(GRID_SIZE-2)){ positionArray.push(position + GRID_SIZE*2) }
    if (position > (GRID_SIZE - 1)){positionArray.push(position - GRID_SIZE) }
    if (position > (2*GRID_SIZE - 1)){positionArray.push(position - GRID_SIZE*2) }
    if (position%GRID_SIZE < (GRID_SIZE - 1) && position < GRID_SIZE*(GRID_SIZE-1)){ positionArray.push(position + (GRID_SIZE + 1)) }
    if (position%GRID_SIZE > 0 && position > (GRID_SIZE - 1)){ positionArray.push(position - (GRID_SIZE + 1)) }
    if (position%GRID_SIZE > 0 && position < GRID_SIZE*(GRID_SIZE-1)){ positionArray.push(position + (GRID_SIZE - 1)) }
    if (position%GRID_SIZE < (GRID_SIZE - 1) && position > (GRID_SIZE - 1)){ positionArray.push(position - (GRID_SIZE - 1)) }
    if ((position)%GRID_SIZE < (GRID_SIZE - 1)){positionArray.push(position + 1) }
    if ((position)%GRID_SIZE < (GRID_SIZE - 2)){ positionArray.push(position + 2) }
    if ((position)%GRID_SIZE > 0){ positionArray.push(position - 1) }
    if (position%GRID_SIZE > 1){ positionArray.push(position - 2) }
    return positionArray }

  function freeDependencies(position){
    const neibouringPositions = getNeighbouringPositions(position)
    const newArray = [...diskPositionDependencies]
    for (let index = 0; index < neibouringPositions.length; index++) {
      newArray[neibouringPositions[index]]=newArray[neibouringPositions[index]]-1
      
    }      
    setDiskPositionDependencies(newArray)
  }

  //testData in Node: console.log(currentlyFreePositions([11, 27,46,60]), 'should be [0,1,5,6,7,8,14,15,16,17,21,22,23,24,31,32,33,40,41,42,48,49,50,56,57,63]') - TESTED CORRECT
  //testData in Node: console.log(currentlyFreePositions([3, 40,28,53,15]), 'should be [0,8,9,16,17,18,25,34,38,39,43,47,50,57,58,59,63]') - TESTED CORRECT
// const GRID_SIZE=8;  
function currentlyFreePositions(currentlyOccupiedPositions){
    const allPotentialPositions = [];
    for (let index = 0; index < (GRID_SIZE*GRID_SIZE); index++) {allPotentialPositions.push(index);}
    const unavailablePositions = [];
    // console.log({currentlyOccupiedPositions})
    for (let i = 0; i < currentlyOccupiedPositions.length; i++) {
    unavailablePositions.push(...getNeighbouringPositions(currentlyOccupiedPositions[i]));
    // console.log({unavailablePositions})
    }
    // console.log('allpotentialPositions', allPotentialPositions.filter(position => !unavailablePositions.includes(position)))
    
    return allPotentialPositions.filter(position => !unavailablePositions.includes(position));
  }

  //testData in Node: for(let i = 0; i < 500; i++) { console.log(getFreePositionsByNum(8), i) }  TESTED didn't fail for several hundred tests
  //testData in Node: for(let i = 4; i < 16; i++) { console.log(getFreePositionsByNum(i), i) } TESTED regularly fails for i > 10 but not for 10 or less
  // copy in GRID_SIZE and currentlyFreePositions and get Neighbouring positions above
  function getFreePositionsByNum(num){
    const diskPositions = []
    for (let index = 0; index < num; index++) {
      const freeArrayPositions = currentlyFreePositions(diskPositions)
      diskPositions.push(freeArrayPositions[Math.floor(Math.random()*freeArrayPositions.length)])
    }
    return diskPositions  }

  //testData in Node:  for(let i = 0; i < 100; i++){console.log((randomFreePosition()),i, 'should not be 1,2,3,4,5,6,7,10,11,12,13,14,15,19,20,21,22,23,24,26,27,28,29,30,31,32,33,35,36,37,40,41,42,44,45,46,48,49,51,52,53,54,55,56,60,61,62')}
// const disks2Display = [{position: 3, other: 'data'},{position: 40, other: 'data'},{position: 28, other: 'data'},{position: 15, other: 'data'},] 
  //testData in Node:  for(let i = 0; i < 100; i++){console.log((randomFreePosition()),i, 'should not be 1,2,3,4,5,6,7,10,11,12,13,14,15,16,19,22,23,24,25,31,32,33,34,37,40,41,42,44,45,46,48,49,51,52,53,54,55,56,60,61,62')}
// const disks2Display = [{position: 3, other: 'data'},{position: 40, other: 'data'},{position: 32, other: 'data'},{position: 15, other: 'data'},]  TESTED CORRECT
  function randomFreePosition(){
    const occupied = disks2Display.map(disk => disk.position)
    if (isExpressionShown){
      occupied.push(5,6,7)
    }
      // console.log({occupied})
    const freeArrayPositions = currentlyFreePositions(occupied)
    // console.log({freeArrayPositions})
    const position = freeArrayPositions[Math.floor(Math.random()*freeArrayPositions.length)]
    return position }
  
// TESTED
  //testData in Node: console.log(getSecureFactors(66), 'should be 1,3,33'); console.log(getSecureFactors(9), 'should be 1,3,9'); console.log(getSecureFactors(55), 'should be 1'); 
// const securedNumbers = [1,3,8,14,9,20,33]
  function getSecureFactors(num){
    const rtnArray = []
    const sqrRoot = Math.sqrt(num)
    if (securedNumbers.includes(sqrRoot)){rtnArray.push(sqrRoot)}
    for (let index = 1; index < sqrRoot; index++) {
      // console.log('index', index, sqrRoot)
      if(Number.isInteger(num/index)){
        if (securedNumbers.includes(num/index)){
          rtnArray.push(num/index)
        } 
        if (securedNumbers.includes(index)){
          rtnArray.push(index)
        }
      }
    }
    return (rtnArray.includes(num)) ? rtnArray : [...rtnArray, num];
}

function getFactors(num){
  const rtnArray = []
  const sqrRoot = Math.sqrt(num)
  if(Number.isInteger(sqrRoot)) {
    rtnArray.push(sqrRoot)
  }
  for (let index = 1; index < sqrRoot; index++) {
    // console.log('index', index, sqrRoot)
    if(Number.isInteger(num/index)){
        rtnArray.push(num/index)
        rtnArray.push(index)
      }
    }
  
  return rtnArray;

}

        // setDisks2Display - use PCArray to set Disks2Display here.  May need to be cleared when starting up Col2Num
        // console.log('earnedNumbers', earnedNumbers)
        function chooseNumber() {
          // const tempTestNumbers = testNumbers
          const element = Math.floor(Math.random()*testNumbers.length)
         
          const chosenNumber = testNumbers[element]
          // tempTestNumbers.splice(element, 1)
          // console.log({chosenNumber})
          // console.log({testNumbers})
          // console.log('testNumbers.filter((testNum) => testNum !== element)', testNumbers.filter((testNum) => testNum !== element), element)
          setTestNumbers(oldTestNumbers => oldTestNumbers.filter((testNum) => testNum !== chosenNumber))
          return chosenNumber
      }
     
//test data console.log('amt of disks in 60',amtOfDisks(primeColoursfactorise(60))); console.log('amt of disks in 1',amtOfDisks(primeColoursfactorise(1)));console.log('amt of disks in 160',amtOfDisks(primeColoursfactorise(160)));   
//     const tempPCArray = [[1,0,0,0,0,0,0,0,0]]; for (let j = 0; j <= 200; j++) { tempPCArray.push(primeColoursfactorise(j)) }
      function amtOfDisks(pcNumArray){
          let returnVal = 0
          // console.log('pcNumArray', pcNumArray)
          for (let index = 2; index < pcNumArray.length-1; index++) {
              // console.log(pcNumArray[index])
              returnVal = returnVal + pcNumArray[index]
          }
          return returnVal 
      }

      function displayCardNum(num){
          // console.log('pcArray[num]', pcArray[num])
          const colourArray = ['black', 'white', 'red', 'green', 'blue', 'purple', 'yellow', 'orange', 'pink']
          setDisks2Display([])
          const positions = getFreePositionsByNum(amtOfDisks(pcArray[num]))
          // console.log('positions', positions)
          let count = 0
          for (let index = 2; index < pcArray[num].length-1; index++) {
              const numDisks = pcArray[num][index];
              const colour = colourArray[index]

              for (let j = 0; j < numDisks; j++) {
                  // console.log('colour', colour)
                  setDisks2Display((oldDisks2Display) => {
                      const diskID = colour + count + (new Date).getTime()
                      // console.log('diskID', diskID)
                      
                      const newDisk = {
                        diskID,
                        colour, 
                        position: positions[count%positions.length], 
                        gridSize: GRID_SIZE                        
                      }
                      count++
                      // console.log('newDisk', newDisk)
                    return [...oldDisks2Display, newDisk]
                  })
                  
              }
              
          }
      }



function showTip(tip){
  // console.log('tip', tip)
}

function setDisks(multiple, colour) {
  setCardNum(prevCardNum => prevCardNum * multiple)
    const diskID = colour + (new Date).getTime()
                
    setDisks2Display((oldDisks2Display) => {

      const position = randomFreePosition()
      const newDisk = {
        diskID,
        colour, 
        position, 
        gridSize: GRID_SIZE                        
      }
    return [...oldDisks2Display, newDisk]
  })
  return diskID
}

function setDataInLogger(docId, arrayLabel){
  // console.log({dataLogger}, {docId}, {arrayLabel}, dataLogger[arrayLabel])
  setDoc(doc(db, docId), dataLogger[arrayLabel], {merge: true})

}

// function resetGame(){
//   setHasStarted(false);
//   // setFoundNumbers([]);
//   // setEarnedNumbers([]);
//   // setClickedNumbers([]);
// console.log('resetGame')
//   setCardNum(1);
// }




  const value = {
      numberRange, setNumberRange,
      numberViewRange, setNumberViewRange, 
      onViewRangeChange,  
      cardNum, setCardNum, 
      colourBtns, pcArray,
      isCardFlipped, setCardFlipped,
      disks2Display, setDisks2Display, setDisks,
      diskPositionDependencies, setDiskPositionDependencies,
      freeDependencies,
      randomFreePosition, getFreePositionsByNum,
      POSITION_ARRAY, 
      COLOUR2MULTIPLE, 
      GRID_SIZE,
      IsAspectRatioLandscape, 
      userID,
      dateObject,
      levelOfPlay,
      getSecureFactors, getFactors, packArray, packIsUnshuffled,
      dailyStreak, setDailyStreak, hourAsKeyDiff, dailyStreakUpdated, setDailyStreakUpdated, setHighestDailyStreak, highestDailyStreak,
      securedNumbers, setSecuredNumbers,
      numTimesFound, setNumTimesFound,
      failedAttempts, setFailedAttempts,
      firstFind, setFirstFind,
      earnedNumbers, setEarnedNumbers, maxScore,
      foundNumbers,  setFoundNumbers,       
      introNumbers, setIntroNumbers,
      clickedNumbers,setClickedNumbers,
      appSize, appWidth,  appHeight, 
      score, memoryEstimate, runningScore, setGameScore,
      hasLoaded,
      areDataIdsReady,
      hasStarted, setHasStarted,
      isNotified, setIsNotified,
      sessionDocId,
      dashDocId,
      testNumbers,setTestNumbers,surveyNumbers, setSurveyNumbers,setIsFlashTestStarted, setIsFactExpStarted,
      setAmtOfTestCards, setIsExpressionShown,setFactoriseExpressions, factoriseExpressions,
      awards, setAwards,checkAndSetAwards,
      awardInfoArray,
      showTip, setHelpModeOn, helpModeOn,
      numOfHints, setNumOfHints, showHint,
      forgetCurve, 
      numberStability,
      NUMBER_STABILITY_BASE,
      getDateObject,
      setNumberOfFinds,setTimeOfLastNewFind,
      timeOfLastNewFind, timeUntilNextSession, dateTimeFromUnixMillisecs, timeOfLastFlashTest,
      timeDiffInHoursMinsSecs, convertMillisecsToHMS, formatSingleDigitMinsSecs,
      setNumberOfBtnClicks, exitLink, setExitLink,
      dataLogger, setDataLogger, updateDataLogger, setDataInLogger,
      amtOfDisks, chooseNumber, displayCardNum,
      goToOutro, setGoToOutro,
      feedbackDocId,scoreDocId
  }

  return (
    <AppContext.Provider value={value}>
        { children }

    </AppContext.Provider>
  )

}

const NUMBER_STABILITY_BASE = 100000
const GRID_SIZE = 8


  const POSITION_ARRAY = () => {
    const isPositionFree = []
    for (let i = 0; i < (GRID_SIZE * GRID_SIZE); i++) {
      isPositionFree[i] = 0;
      
    } 
    return isPositionFree
  }
  
  const COLOUR2MULTIPLE = {
        black: 0,
        white: 1,
        red: 2,
        green: 3,
        blue: 5,
        purple: 7,
        yellow: 11,
        orange: 13,
        pink: 17
  }
  

    const colourBtns = [
      {
        label: 'black-btn',
        colour: 'black',
        number: 0,
        left: 45,
        level: [1,2,3,4,5,6,7,8,9]
      },
      {
        label: 'white-btn',
        colour: 'white',
        number: 1,
        left: 53,
        level: [1,2,3,4,5,6,7,8,9]
      },
      {
        label: 'red-btn',
        colour: 'red',
        number: 2,
        left: 60,
        level: [2,3,4,5,6,7,8,9]
      },
      {
        label: 'green-btn',
        colour: 'green',
        number: 3,
        left: 67,
        level: [4,5,6,7,8,9]
      },
      {
        label: 'blue-btn',
        colour: 'blue',
        number: 5,
        left: 75,
        level: [2,3,4,5,6,7,8,9]
      },
      {
        label: 'purple-btn',
        colour: 'purple',
        number: 7,
        left: 84,  
        level: [5,6,7,8,9]
      },
      {
        label: 'yellow-btn',
        colour: 'yellow',
        number: 11,
        left: 94,
        top: 10,
        hoverTop: -3,
        level: [3,4,5,6,7,8,9]
      },
      {
        label: 'orange-btn',
        colour: 'orange',
        number: 13,
        left: 95.1,
        top: 80,
        hoverTop: 70,
        level: [6,7,8,9]
      },
      {
        label: 'pink-btn',
        colour: 'pink',
        number: 17,
        left: 95.1,
        top: 180,
        hoverTop: 170,
        level: [8,9]
      }
  
    ]
//Awards [0] = colourBttns, [1] = guessing lots of times and finally succeeding, [2] = flash card correct, [3] = finding a number greater than 100, [4] = finding 10 multiples of 10, [5] = securing numbers
const awardInfoArray = [
{
  src: 'images/award1.png',
  alt: 'Award for discovering how to make numbers with colours',
  tip: 'award_colour_btns',
  tour: 'awards-tour-0'
},
{
  src: 'images/award2.png',
  alt: 'Award for trying, failing and trying again',
  tip: 'award_try_try_again',
  tour: 'awards-tour-1'
},
{
  src: 'images/award6.png',
  alt: 'Start boosting your memory by testing your recall of numbers you recently discovered',
  tip: 'award_flash_test',
  tour: 'awards-tour-2'
},
{
  src: 'images/award4.png',
  alt: 'You can now look for numbers that are greater than 100.  To keep this award, you need to practice regularly.  If your score drops below 55, you lose this award',
  tip: 'award_number_range_increase',
  tour: 'awards-tour-3'
},
{
  src: 'images/award3.png',
  alt: 'Well done on finding 10 multiples of 10. Have you found the full triangle of numbers that include the 10, 1 and 11 times table?',
  tip: 'award_ten_multiples_10',
  tour: 'awards-tour-4'
},
{
  src: 'images/award5.png',
  alt: 'You have started to lock numbers into long term memory',
  tip: 'award_number_lock',
  tour: 'awards-tour-5'
},

]
