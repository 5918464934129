import React, {useRef, useState, useEffect} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { Container } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import Logo from './Logo'


export default function Signup() {
    // console.log('Signup render')
    
    const userNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup, currentUser } = useAuth() 
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        console.log('currentUser', currentUser);
        
        if(currentUser.userName !== "guest"){
            navigate('/');
        }

    }, [])

    async function handleSubmit(e) {
        e.preventDefault()

        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Passwords Do Not Match')
        }

        try{

            setError('')
            setLoading(true)
            await signup(userNameRef.current.value, emailRef.current.value, passwordRef.current.value)
            navigate("/")
        } catch {
            setError('Failed to sign up')
        }

        setLoading(false)
    }

    


    return (
    <>
        <Logo />
        <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "50vh"}}>
      <div className="w-100" style={{maxWidth: "400px"}}>

        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">
                    Sign Up
                </h2>
                
                {error && <Alert variant='danger'>{ error }</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="username">
                        <Form.Label>Decide on a Username</Form.Label>
                        <Form.Control type="username" ref={userNameRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} required></Form.Control>
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" ref={passwordConfirmRef} required></Form.Control>
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-3" type="submit">Sign Up</Button>
                </Form>
        <div className="w-100 text-center mt-2">
            Already have an account? <Link to="/login">Log In</Link>
        </div>
            </Card.Body>
        </Card>

        </div>  
                
                </Container>

    </>
  )
}
