import React, { useContext, useEffect, useState } from 'react'
import { auth, db } from '../firebase'
import { doc, setDoc, getDoc } from "firebase/firestore"
import { useNavigate } from "react-router-dom"
import { createUserWithEmailAndPassword,
  signInWithEmailAndPassword, sendPasswordResetEmail, updateEmail, 
  updatePassword, onAuthStateChanged

} from 'firebase/auth'
// import { setUser4Db } from '../utils'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [firestoreIds, setFireStoreIds] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const navigate = useNavigate()

  function signup(userName, email, password){
    createUserWithEmailAndPassword(auth, email, password).then((cred) => {
      console.log('cred', cred)
      const data = {
        role: 'student',
        userName
      }
      setDoc(doc(db, 'users/'+ cred.user.uid), data, {merge: true})
      // console.log('user created:', cred.user)
    })
  }

  function login(email, password){
    setError('')
    signInWithEmailAndPassword(auth, email, password).then((cred) => {
      // console.log('user logged in:', cred.user)
       navigate("/")
    }).catch((error) => {
      setError('failed to log in')
      // console.log('failed to log in', error) 
       })               
  }

  function resetPassword(email){
    sendPasswordResetEmail(auth, email)
  }

  function update_userName(userName){
    console.log('currentUser', currentUser)
    console.log('userName', userName)
    setDoc(doc(db, 'users/'+ currentUser.uid), {userName}, {merge: true})
  }

  function update_email(email){
    // console.log('updateEmail', email)
    updateEmail(auth.currentUser, email)
  }

  function updatePW(password){
    updatePassword(auth.currentUser, password)
  }

  function logout() {
    window.location.reload(false);
    return auth.signOut()
  }

  async function handleLogout() {
    setError('')

    try {
      await logout()
      navigate('/login')

    } catch {
      setError('Failed to Log Out')
    }
  }



  useEffect(() => {

    onAuthStateChanged(auth, (user) => {
      // console.log("AuthStateChanged", user)
      // setFireStoreIds(setUser4Db)
      let userName = 'guest';
      setCurrentUser({...user, ...{userName}}) 
      if(user !== null){
      getDoc(doc(db, 'users/' + user.uid )).then((docSnap) => {
        // console.log(docSnap.data())
          if (docSnap.data().userName){
           userName = docSnap.data().userName;
          }
          else {
            userName = ''
          }
          setCurrentUser({...user, ...{userName}}) 
        })
    }

      setLoading(false)
    })


  }, [])

  

  const value = {
      currentUser, setCurrentUser,
      login,
      signup,
      logout,
      resetPassword, 
      update_email,
      update_userName,
      updatePW, 
      handleLogout,
      error,
      firestoreIds
  }
  
  return (
    <AuthContext.Provider value={value}>
        {!loading && children}

    </AuthContext.Provider>
  )
}
