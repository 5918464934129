import React from 'react'
import { Button, Container, Row, Col, Card } from 'react-bootstrap'
import { useApp } from './contexts/AppContext'
import { Link, useNavigate } from "react-router-dom"
import { doc, setDoc } from "firebase/firestore"
import { db } from "./firebase"
import { useMessaging } from './hooks/useMessaging'

export default function RedPillBluePill() {
const { 
    setHelpModeOn,
    userID, currentUser,
    earnedNumbers,
    testNumbers,
    timeUntilNextSession, timeOfLastNewFind,
    hasStarted, setHasStarted, goToOutro, setGoToOutro,
    memoryEstimate, awards, setExitLink
    } = useApp() 
    const prevCardsFound = earnedNumbers.filter(num => !isNaN(num)).length
    const [ mission, missionOptions, supportVids] = useMessaging(prevCardsFound, memoryEstimate, awards, userID) 
    const navigate = useNavigate()


    function missionAction(keyword){

    console.log('keyword', keyword)

    if (keyword === 'scores'){
        setExitLink({path: '/', text: 'Mission'})
        navigate('/ScoreCard')
        return
    }
    if (keyword === 'login'){
        navigate('/login')
        return
    }
    if (keyword === 'explore'){
        setHasStarted(true)
        // console.log('testNumbers', testNumbers.length)
        setGoToOutro(true);
        navigate("/explore")
        return
    }
    if (keyword === 'guidedTour'){
        startHelpedSession()
        return
    }  if (keyword === 'flash'){
        startSession()
        return
    }  if (keyword === 'checkScores'){
    
        return
    }
    
    }
        function startSoloSession() {
        setHelpModeOn(false)
        startSession()
        }
    
        function startHelpedSession() {
        setHelpModeOn(true)
        // console.log({userID}, {currentUser})
        setDoc(doc(db, 'users/'+ userID), {helpOn: true}, {merge: true})

        startSession()
        }
    
    function startSession() {
    setHasStarted(true)
    // console.log('testNumbers', testNumbers.length)
    setGoToOutro(true);
    // console.log('goToOutro', goToOutro)
    if (testNumbers.length > 0){
        navigate("/flashTest")
    }
    else {
    navigate("/explore")          
    }

    }
    // console.log('missionOptions', missionOptions)  

    return (

    <Container>
        <Row 
        style={{ color: "black" }}
        >
        <Col>
        <h3>Your Choices</h3>
        </Col>
        </Row>
        <Row 
            className="mb-5"
            style={{ color: "white" }}>
            <Col md={2}></Col>
            <Col md={3}>
            <Button 
            className="w-100"
            style={{ backgroundColor: "red",
            borderRadius: "40%"    
                }}
            onClick={() => missionAction(missionOptions.red.action)}>{ missionOptions.red.label }</Button>                 
            </Col>
            <Col md={2}></Col>              
            <Col md={3}>
            <Button 
            className="w-100"
            style={{ 
            backgroundColor: "blue",
            borderRadius: "40%"    
                    
            }}
            onClick={ () => missionAction(missionOptions.blue.action) }>{ missionOptions.blue.label }</Button> </Col>
            <Col md={2}></Col>
            </Row>
    </Container>
    )
}


