import React from 'react'
import { useApp } from './contexts/AppContext'
import NumBtnStyled from './NumBtnStyled'
import NumBtnFront from './NumBtnFront'
import NumBtnBack from './NumBtnBack'

                 
export default function NumBtnStyledCombo({ btnOpacity, appSize, value, isFlipped, isSecured, isFoundNumber, isCorrectAnswer, onClick}) {
    // console.log('NumBtnStyledCombo')
  return (
    <NumBtnStyled
        className={`${ isFlipped || isSecured ? 'num-btn-off' : 'num-btn-on'}`}
        appSize={appSize}
        isFlipped={isFlipped}
        isFoundNumber={isFoundNumber}
        isSecured={isSecured}
        isCorrectAnswer={isCorrectAnswer}
        btnOpacity={btnOpacity}
    >
        <NumBtnFront
            onClick={onClick}
            appSize={appSize}
            is3Digit = {(value >= 100)}
            is1Digit = {(value <= 9)}
            isSecureOrFound = {(isSecured || isFoundNumber)}
        >
            {isFlipped ? null : value}
        </NumBtnFront>
        <NumBtnBack
            appSize={appSize}
        >
            {isFlipped ? <img src="images/noEntry.png" alt="wrong number" height={`${appSize/17}px`} /> : null}
        </NumBtnBack>
    </NumBtnStyled>
  )
}

NumBtnStyledCombo.whyDidYouRender = true

