import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap';
import { db } from "./firebase"
import { doc, query, where, addDoc, setDoc, collection, getDocs, serverTimestamp, Timestamp, getDoc } from "firebase/firestore"

export default function WeeklyActivity() {

    const [dataObject, setDataObject] = useState({})
    const [userDataOutput, setUserDataOutput] = useState()
    const currentTimestamp = Date.now()
    const todayDateObj = getDateObject(Date.now())
    const startDateObj = getDateObject(new Date(currentTimestamp - 20*24*60*60*1000))
    console.log('todayobj', todayDateObj, 'yester', startDateObj)
    const yesterdaysFBTimestamp = Timestamp.fromDate(new Date(currentTimestamp - 20*24*60*60*1000))
    const startTimestamp = startDateObj.millisecs


    const dateRange = () => {
        const todaydate = new Date(currentTimestamp)
        const startDate = new Date(startTimestamp)
        return startDate.toDateString()+ " - " + todaydate.toDateString()
    }

    function getDateObject(givenDate){
        const returnObj = {}
        const todaysDate = new Date(givenDate)
        const monthNumber = todaysDate.getMonth()+1
        const dateNumber = todaysDate.getDate()
        const hourNumber = todaysDate.getHours()
        const monthDigits = (monthNumber > 9) ? monthNumber : '0'+monthNumber
        const dateDigits = (dateNumber > 9) ? dateNumber : '0'+dateNumber
        const hourDigits = (hourNumber > 9) ? hourNumber : '0'+hourNumber
        const dateAsString = todaysDate.getFullYear()+' '+monthDigits+' '+dateDigits+' '+hourDigits
        returnObj['dateAsKey'] = dateAsString.replace(/\s+/g, '')
        const timestamp = Timestamp.fromDate(todaysDate)
        returnObj['millisecs'] = Math.round((timestamp.seconds*1000000000 + timestamp.nanoseconds)/1000000)
        return returnObj
      }


      useEffect(() => {
// Produce a list of users that link to a way to isolate that data and a calendar of each of the hours in those 7 days also with a way to drill into those data items.  Each cell in that calendar table represents an hour and can have data associated with that hour num users, sessions, clicks etc.
        const sessionColRef = collection(db, 'dashdata')
        console.log('todayobj', todayDateObj, 'yester', startDateObj)

        const q = query(sessionColRef, where('MillisecStarted', '>', startDateObj.millisecs))
        getDocs(q).then((snapshot) => {
            // const tempObj = {
            //     numSessions: 0,
            //     btnClicks: 0,
            //     colourClicks: 0,
            //     finds: 0, 
            //     lastSession: 0
            // }
            // const userData = { }
            console.log('snapshot.docs', snapshot.docs[0].id)
            snapshot.docs.forEach((doc) => {

                console.log('data in each doc', doc.data(), doc.id)
            //     doc.data().userIds.forEach(element => {
            //         if (!userData[element]){
            //             userData[element] = {
            //                 numSessions: 0,
            //                 btnClicks: 0,
            //                 colourClicks: 0,
            //                 finds: 0, 
            //                 lastSession: 0
            //             }
            //         }
            //         let count = 0
            //         if (doc.data().sessions){
            //             Object.values(doc.data().sessions).forEach(sessionElement => {
            //                 if (element === sessionElement) count = count + 1 
            //             });
            //         }
            //         const userBtnKey = 'btnClicks@'+element
            //         const userColourKey = 'colourBtnClicks@'+element
            //         const userFindsKey = 'finds@'+element
            //         const userMillisecs = 'MillisecStarted';
            //         tempObj.btnClicks = ((doc.data()[userBtnKey]) ? doc.data()[userBtnKey] : 0)+ tempObj.btnClicks
            //         tempObj.colourClicks = ((doc.data()[userColourKey]) ? doc.data()[userColourKey] : 0) + tempObj.colourClicks
            //         tempObj.finds = ((doc.data()[userFindsKey]) ? doc.data()[userFindsKey] : 0) + tempObj.finds
            //         tempObj.numSessions = count + tempObj.numSessions
            //         userData[element].numSessions = count + userData[element].numSessions
            //         userData[element].btnClicks = ((doc.data()[userBtnKey]) ? doc.data()[userBtnKey] : 0) + userData[element].btnClicks 
            //         userData[element].colourClicks = ((doc.data()[userColourKey])? doc.data()[userColourKey] : 0) + userData[element].colourClicks
            //         userData[element].finds = ((doc.data()[userFindsKey]) ? doc.data()[userFindsKey] : 0) + userData[element].finds
            //         userData[element].lastSession = ((doc.data()[userMillisecs] > userData[element].lastSession) ? doc.data()[userMillisecs] : userData[element].lastSession) 
            //         // console.log('userData', userData)
            //     });


            })   
            // // console.log('tempObj', tempObj)
            // setDataObject(tempObj)
            // // console.log('userObj', userData)
            // setUserDataOutput(userDataOutputFunction(userData))
        })
}, [])


  return (
    <div>MonthlyActivity</div>
  )
}
