import styled from 'styled-components'

// console.log('NumBtnFront render')


const NumBtnFront = styled.div`
    position: absolute;
    /* padding-left: ${props => props.is3Digit ? 0 : props.is1Digit ? props.appSize/50 : props.appSize/65}px; */
    width: 100%;
    text-align: center;
    padding-top: ${props => props.appSize/80}px;
    bottom: ${props => props.isSecureOrFound ? 2 : props.appSize/120}px;
    cursor: pointer;
    backface-visibility: hidden;
`

export default NumBtnFront