import React from 'react'

export default function SurveyOption({optionType, optionText, isOptionChosen,  onClick}) {


  // console.log('keyNum', keyNum)
  // console.log('optionType', optionType)
  // console.log('optionText', optionText)
  // console.log('question', question)


  return (
    <div
    onClick={onClick}
    >
      {(optionType === 'text') ? {optionText} : <img 
      src={`images/icons/${optionText}.png`}
      style={{backgroundColour: (isOptionChosen) ? 'yellow': 'black'}} 
      width='100%' 
      />}
    </div>
  )
}
