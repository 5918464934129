import React, {useRef, useState} from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useAuth } from './contexts/AuthContext'
import { Container } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"


export default function UpdateProfile() {
    const userNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, update_email, update_userName, updatePW } = useAuth() 
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    // console.log('UpdateProfile render')

    console.log('currentUser', currentUser)

    function handleSubmit(e) {
        e.preventDefault()

        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Passwords Do Not Match')
        }

        const promises = []
        setLoading(true)
        setError('')

        if (emailRef.current.value !== currentUser.email){
            promises.push(update_email(emailRef.current.value))
        }

        if (userNameRef.current.value !== currentUser.userName){
            promises.push(update_userName(userNameRef.current.value))
        }

        if (passwordRef.current.value){
            promises.push(updatePW(passwordRef.current.value))
        }

        Promise.all(promises).then(() => {
            navigate('/')
        }).catch(() => {
            setError('Failed to Update Account')
        }).finally(() => {
            setLoading(false)
        })

        try{

            setError('')
            setLoading(true)
                // await signup(emailRef.current.value, passwordRef.current.value)
            navigate("/")
        } catch {
            setError('Failed to Update Profile')
        }

        setLoading(false)
    }

    return (
    <>
        <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "50vh"}}>
      <div className="w-100" style={{maxWidth: "400px"}}>

        <Card>
            <Card.Body>
                <h2 className="text-center mb-4">
                    Update Profile
                </h2>
                
                {error && <Alert variant='danger'>{ error }</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="username">
                        <Form.Label>Decide on a Username</Form.Label>
                        <Form.Control type="text" ref={userNameRef} defaultValue={(currentUser) ? currentUser.userName : ''}></Form.Control>
                    </Form.Group>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email}></Form.Control>
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} placeholder="Leave blank to keep the same"></Form.Control>
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" ref={passwordConfirmRef} placeholder="Leave blank to keep the same"></Form.Control>
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-3" type="submit">Update Profile</Button>
                </Form>
        <div className="w-100 text-center mt-2">
            No Changes needed? <Link to="/login">Cancel</Link>
        </div>
            </Card.Body>
        </Card>

        </div>  
                
                </Container>

    </>
  )
}
